import { Component } from '@angular/core';

@Component({
  selector: 'app-external-aircraft-designate',
  templateUrl: './external-aircraft-designate.component.html',
  styleUrl: './external-aircraft-designate.component.scss'
})
export class ExternalAircraftDesignateComponent {

}
