<div class="grid px-6 mb-3">
    <div class="col-12">
        <h1>Welcome to the USAS Help Documentation page</h1>
        <p>Here you will find all the information you need to get started and troubleshoot common issues.</p>
        <hr>
    </div>
</div>
<div id="internal-content" class="hidden">
    <div class="grid nested-grid px-6">
        <div class="lg:col-6 md:col-6 sm:col-12">
            <p-card header="Internal User Login/Functions" 
                subheader="This section provides guidance on internal login process using a PIV/CAC card." role="region" [style]="{ background: '#8080801a' }">
                <ng-template pTemplate="header">
                    <i class="fa fa-user-plus pl-4 pt-4 text-color-primary fa-lg" aria-hidden="true"></i>
                </ng-template>
                <ul class="my-0">
                    <li>
                        <a class="font-medium no-underline link-button cursor-pointer ml-0 text-color-primary" (click)="navigateToInternal()">Login as an Internal User</a>
                    </li>
                </ul>
            </p-card>
        </div>
        <div class="lg:col-6 md:col-6 sm:col-12">
            <p-card header="Search Functionality" 
                subheader="This section provides guidance on search features available to the internal users to search for U.S. Agent information." role="region" [style]="{ background: '#8080801a' }">
                <ng-template pTemplate="header">
                    <i class="fa fa-search pl-4 pt-4 text-color-primary fa-lg" aria-hidden="true"></i>
                </ng-template>
                <ul class="my-0">
                    <li>
                        <a class="font-medium no-underline link-button cursor-pointer ml-0 text-color-primary" (click)="navigateToInternalUserSearch()">Search for Airman/Operator/Aircraft Owner's agent information</a>
                    </li>
                </ul>
            </p-card>
        </div>
    </div>

    <div class="grid nested-grid px-6">
        <div class="lg:col-6 md:col-6 sm:col-12">
            <p-card header="Manage FAA Users" 
                subheader="This section provides guidance for internal admins to add/manage FAA users." role="region" [style]="{ background: '#8080801a' }">
                <ng-template pTemplate="header">
                    <i class="fa fa-user-group pl-4 pt-4 text-color-primary fa-lg" aria-hidden="true"></i>
                </ng-template>
                <ul class="my-0">
                    <li>
                        <a class="font-medium no-underline link-button cursor-pointer ml-0 text-color-primary" (click)="navigateToInternalUserManage()">Add/manage FAA users</a>
                    </li>
                </ul>
            </p-card>
        </div>
    </div>
</div>
<div id="external-content" class="hidden">
    <div class="grid nested-grid px-6">
        <div class="lg:col-6 md:col-6 sm:col-12">
            <p-card header="Create/Manage Account" 
                subheader="This section provides guidance on new user account requests, including entering personal information, setting up security questions, and activating the account." role="region" [style]="{ background: '#8080801a' }">
                <ng-template pTemplate="header">
                    <i class="fa fa-regular fa-address-card pl-4 pt-4 text-color-primary fa-lg" aria-hidden="true"></i>
                </ng-template>
                <ul class="my-0">
                    <li>
                        <a role="link" tabindex="0" class="font-medium no-underline link-button cursor-pointer ml-0 text-color-primary" (click)="navigateToExternalUserLogin()">Steps to request a new user account as an external user</a>
                    </li>
                    <li>
                        <a role="link" tabindex="0" class="font-medium no-underline link-button cursor-pointer ml-0 text-color-primary" (click)="navigateToExternalPassword()">Reset your account password</a>
                    </li>
                </ul>
            </p-card>
        </div>
        <div class="lg:col-6 md:col-6 sm:col-12">
            <p-card header="Add/Designate U.S. Agent" 
                subheader="This section provides guidance on how to add/designate a U.S. Agent and filling out the necessary details." role="region" [style]="{ background: '#8080801a' }">
                <ng-template pTemplate="header">
                    <i class="fa fa-solid fa-flag-usa pl-4 pt-4 text-color-primary fa-lg" aria-hidden="true"></i>
                </ng-template>
                <ul class="my-0">
                    <li>
                        <a role="link" tabindex="0" class="font-medium no-underline link-button cursor-pointer ml-0 text-color-primary" (click)="navigateToExternalUserDesignate()">Add/Designate a U.S. Agent as an Airman</a>
                    </li>
                    <li>
                        <a role="link" tabindex="0" class="font-medium no-underline link-button cursor-pointer ml-0 text-color-primary" (click)="nativateToAcircraftDesignate()">Add/Designate a U.S. Agent as an Aircraft Owner</a>
                    </li>
                </ul>
            </p-card>
        </div>
    </div>
</div>


<div class="container">
    <div class="category-grid overflow-hidden">
        <div id="external-content" class="hidden">
            <ul class="list-unstyled row mb-0">
                <li class="md:col-6 lg:col-4 border-right border-bottom">
                    <a class="block p-6 mb-5 h-full text-center hover:no-underline transition hover:float"
                        (click)="navigateToExternalUserLogin()">
                        <i class="fa fa-user-plus fa-6" aria-hidden="true"></i>
                        <h3 class="mb-3">
                            External Users - Login/Account Request
                        </h3>

                        <p class="text-base-secondary">
                            This section guides external users on how to create a new user account, including the steps
                            for filling out personal information, setting up security questions, and activating the
                            account via email.
                        </p>

                    </a>
                </li>

                <li class="md:col-6 lg:col-4 border-right border-bottom">
                    <a class="block p-6 mb-5 h-full text-center hover:no-underline transition hover:float"
                        (click)="navigateToExternalPassword()">
                        <i class="fa fa-key" aria-hidden="true"></i>
                        <h3 class="mb-3">
                            External User/Password Reset
                        </h3>

                        <p class="text-base-secondary">
                            This section provides instructions for external users to reset their password if they forget
                            it. It covers the process from requesting a password reset to setting a new password.
                        </p>

                    </a>
                </li>
                <li class="md:col-6 lg:col-4 border-right border-bottom">
                    <a class="block p-6 mb-5 h-full text-center hover:no-underline transition hover:float"
                        (click)="navigateToExternalUserDesignate()">
                        <i class="fa fa-sign-in" aria-hidden="true"></i>
                        <h3 class="mb-3">
                            External User - Designate a U.S. Agent
                        </h3>

                        <p class="text-base-secondary">
                            This section explains how external users can designate a U.S. Agent within the system. It
                            includes details on selecting the type of agent and filling out the necessary information.
                        </p>

                    </a>
                </li>
            </ul>
        </div>
        <!-- <div id="internal-content" class="hidden">
            <ul class="list-unstyled row mb-0">
                <li class="md:col-6 lg:col-4 border-right border-bottom">
                    <a class="block p-6 mb-5 h-full text-center hover:no-underline transition hover:float"
                        (click)="navigateToInternal()">
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                        <h3 class="mb-3">
                            Internal User Login/Functions
                        </h3>

                        <p class="text-base-secondary">
                            This section describes the login process for internal users, including how to sign in using
                            a PIV/CAC card, and outlines the various functions available to internal users, such as
                            searching for agent information and accessing reports.
                        </p>

                    </a>
                </li>
                <li class="md:col-6 lg:col-4 border-right border-bottom">
                    <a class="block p-6 mb-5 h-full text-center hover:no-underline transition hover:float"
                        onclick="alert('Please check back soon.')" href="#">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <h3 class="mb-3">
                            Search Functionality
                        </h3>

                        <p class="text-base-secondary">
                            This section covers the search features available to internal users, allowing them to search
                            for an airman’s agent information using various filters and download search results.
                        </p>

                    </a>
                </li>
                <li class="md:col-6 lg:col-4 border-right border-bottom">
                    <a class="block p-6 mb-5 h-full text-center hover:no-underline transition hover:float"
                        onclick="alert('Please check back soon.')" href="#">
                        <i class="fa fa-users" aria-hidden="true"></i>
                        <h3 class="mb-3">
                            Manage Users
                        </h3>

                        <p class="text-base-secondary">
                            This section is for internal users with administrative privileges, detailing how they can
                            manage user roles and statuses within the system, including editing existing users and
                            updating their information.
                        </p>

                    </a>
                </li>

                <li class="md:col-6 lg:col-4 border-right border-bottom">
                    <a class="block p-6 mb-5 h-full text-center hover:no-underline transition hover:float"
                        onclick="alert('Please check back soon.')" href="#">
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                        <h3 class="mb-3">
                            Add New User
                        </h3>

                        <p class="text-base-secondary">
                            This section provides instructions for internal administrators on how to add a new user to
                            the system, including verifying the user’s email, assigning roles, and setting the user’s
                            status.
                        </p>

                    </a>
                </li>
            </ul>

        </div> -->
    </div>
</div>