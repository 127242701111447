<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">INTERNAL USER- SEARCH</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">Search can be performed by Internal users to look for Agent information associated to an Airmen / Operator OR Aircraft Registered Owner. (Airmen / Operators / Aircraft Registered Owners do not have access to Search).
                </li>
                <li class="mb-3">Once logged in as an Internal user, the radio button for the ‘Airman / Operator’ role is selected by default. Select the appropriate role to see the agent details for either of the roles below:
                    <ul>
                        <li>Airman / Operator</li>
                        <li>Aircraft Registered Owner</li>
                    </ul>
                    <br>Based on the role selected, the search filters as in the screenshots below will vary. Note: By default the ‘Airman / Operator’ role is selected and the corresponding U.S. Agent List is displayed. The default display for the list is based on ‘Last Updated on’  column. 
                    <img class="w-6 block" src="../../../assets/images/internal_image3.png">
                </li>
                <li class="mb-3">If you select the radio button for  ‘Aircraft Registered Owner’, the filters will be updated accordingly to show the N Number and Serial Number along with the existing filters for First Name, Last Name, Updated from Date and Updated to Date.
                    <img class="w-6 block" src="../../../assets/images/search-image3.png">
                </li>
                <li class="mb-3">Use the search filters listed below to refine the seach in the list and get the desired results. Use the pagination at the botton of the list to navigate between pages:
                    <p><b>Role - Airman/Operator</b></p>
                    <ul>
                        <li>First Name</li>
                        <li>Last Name</li>
                        <li>Email</li>
                        <li>FTN</li>
                        <li>Updated from Date</li>
                        <li>Updated to Date</li>
                    </ul>
                    <b>Role - Aircraft Registered Owner</b>
                    <ul>
                        <li>First Name</li>
                        <li>Last Name</li>
                        <li>N Number</li>
                        <li>Serial Number</li>
                        <li>Updated from Date</li>
                        <li>Updated to Date</li>
                    </ul>
                    <p><strong>Note:</strong> The ‘Clear’ button clears the values from the search filter, clears the U.S. Agents list and reverts to default display for the U.S. Agents list to Airman /Operator results.</p>
                </li>
                <li>Once the search is performed, the internal user also has an option to download the search results. This will download the search results in the CSV format and can be used to refine the results further.
                    <img class="w-6 block" src="../../../assets/images/search-img2.png">
                </li>
            </ol>
        </div>
    </div>
</div>