<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">EXTERNAL USERS - LOGIN/ACCOUNT REQUEST</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">Launch the following URL – <a href="https://usas.faa.gov/" target="_blank">https://usas.faa.gov/</a></li>
                <li class="mb-3">You will be redirected to the disclaimer page. Click on ‘Accept’.
                    <img class="w-6 block" src="../../../assets/images/disclaimer-1.png">
                </li>
                <li class="mb-3">You will be redirected to USAS sign in page as in the screenshot below:
                    <img class="w-6 block" src="../../../assets/images/image.png">
                </li>
                <li class="mb-3">Create an account by clicking on ‘Request New User Account’ link.</li>
                <li class="mb-3">You will be redirected to ‘Request an Account’ page and will need to fill out the values for the required fields for the following sections:
                <ul>
                    <li class="mb-3">Personal Information.</li>
                    <li class="mb-3">Security Questions and Answers. <strong>Note:</strong> All three questions
                        should be unique.</li>
                    <li class="mb-3">Create Password.<strong> Note:</strong> Refer to the password requirements.</li>
                    <li class="mb-3">Save security questions, answers, email, and password for future login and password reset. </li>
                    <li class="mb-3">Hover over the icon<span class="fas fa-info-circle fa-sm ml-1" style="font-size: 1rem"></span> to view the tooltip for the associated field.
                    </li>
                </ul>
                <img class="w-6 block" src="../../../assets/images/image2.png">
            </li>
                <li class="mb-3">Once the required information is provided, click on ‘Submit’ at the bottom of the ‘Request an Account’ page.</li>
                <li class="mb-3">Resolve any validation errors and click on ‘Submit’. The following message will be displayed. 
                    <img class="w-6 block" src="../../../assets/images/image3.png">
                </li>
                <li class="mb-3">Once the Account is created, save your email and password to sign into USAS.</li>
                <li class="mb-3">Login to the email account (Inbox) which was used for account creation.</li>
                <li class="mb-3">You will receive the following email in your inbox:
                    <img class="w-6 block" src="../../../assets/images/image4.png">
                </li>
                <li class="mb-3">Click on the ‘Email verification link’ to confirm your email address.</li>
                <li class="mb-3">The message for account activation will be displayed. Click on ‘Return to Sign In’ button.
                    <img class="w-6 block" src="../../../assets/images/image5.png">
                </li>
                <li class="mb-3">You will be redirected to the disclaimer page. Click on ‘Accept’ button.
                    <img class="w-6 block" src="../../../assets/images/disclaimer-1.png">
                </li>
                <li class="mb-3">You will be redirected to the USAS sign in page:
                    <img class="w-6 block" src="../../../assets/images/image.png">
                </li>
                <li class="mb-3">Enter the email and password that was used for account creation and activation and click on ‘Sign in’.
                    <img class="w-6 block" src="../../../assets/images/image8.png">
                </li>
                <li class="mb-3">You will be successfully logged in and will be on the ‘Manage U.S. Agent’ page. You will see the ‘U.S. Agent Information’ section. Select the radio button to designate an agent for either an ‘Airman / Operator’ <strong>OR</strong> an ‘Aircraft Owner’.
                    <img class="w-6 block" src="../../../assets/images/image9.png">
                </li>
            </ol>
        </div>
    </div>
</div>