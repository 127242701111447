<p-toast position="top-center"></p-toast>
<main id="main-content" class="grid nested-grid ">
    <div class="col-3 md:col-3">
        <div class="devkit-left-nav-link-wrapper w-auto" id="devkit-left-nav-link-wrapper" [formGroup]="userLogin">
            <ul class="devkit-left-nav-links border-solid border-gray-200 p-3 bg-gray-100" id="devkit-left-nav-links-login">
                <div class="devkit-nav-heading">External Users</div>
                <p class="text-sm">Please enter email and password to sign in</p>
                <div>
                    <label for="email1" class="block text-900 font-medium mb-1 mt-1">Email</label>
                    <input pInputText formControlName="email" (keyup.enter)="externalUserLogin()" id="email1" type="email" placeholder="Email address" pInputText class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full mb-1">
                    <div *ngIf="userLogin.controls.email.invalid && userLogin.controls.email.touched && userLogin?.controls?.email?.errors?.required"
                        class="alert danger-alert error-font">Email is required.</div>
                    <div *ngIf="userLogin.controls.email.invalid && userLogin.controls.email.touched && !userLogin?.controls?.email?.errors?.required"
                        class="alert danger-alert error-font">Please enter a valid email address.</div>
            
                    <label for="password1" class="block text-900 font-medium mb-1 mt-2">Password</label>
                    <!-- <input pInputText formControlName="password" (keyup.enter)="externalUserLogin()" id="password1" type="password" placeholder="Password" pInputText class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full mb-1"> -->
                    <p-password id="password1" formControlName="password" (keyup.enter)="externalUserLogin()" [feedback]="false" [toggleMask]="true" class="block border-noround" placeholder="Password"/>
                    <div *ngIf="userLogin.controls.password.invalid && userLogin.controls.password.touched"
                        class="alert danger-alert error-font">Password is required.</div>
                </div>
                <button (click)="externalUserLogin()" class="primary default" pButton pRipple type="button" label="Sign in" class="w-full primary default mt-4"></button>
                <div class="flex align-items-center justify-content-start mb-2">
                    <a class="font-medium no-underline link-button cursor-pointer ml-0" (click)="forgotPassword()">Forgot password?</a>
                </div>
                <button pButton pRipple type="button" (click)="createNewAccount()" label="Request New User Account" class="w-full secondary default mt-2"></button>
            </ul>
            <ul class="devkit-left-nav-links border-solid border-gray-200 p-3 bg-gray-100" id="devkit-left-nav-links-login">
                <div class="devkit-nav-heading">FAA Users</div>
                <button pButton pRipple type="button" label="FAA User Sign in" class="w-full primary default mt-3" (click)="login()"></button>
            </ul>
        </div>
    </div>
    <div class="col-9 md:col-9">
        <div class="grid">
            <div class="col-8">
                <h1>Welcome to USAS</h1>
                <hr class="border-top-3 border-yellow-600">
                <div class="pr-3">
                    <p>
                        On October 8, 2024, FAA published <span><a href="https://www.federalregister.gov/documents/2024/10/08/2024-22000/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain" aria-label="Federal Register" title="Federal Register" target="_blank">U.S Agents for Service final rule</a></span>
                    </p>
                    <p>
                        Individuals who have a foreign address and <b>no</b> U.S. physical address of record on file with the FAA are required to designate a U.S. agent for service if they apply for a certificate issued under 14 CFR part 47, 61, 63, 65, 67, or 107 or hold a certificate issued under any of these parts. This requirement applies only to individuals (not entities).
                    </p>
                    <p>
                        <b>Note: </b>If you do not currently have a U.S. physical address of record and are able to provide one, you may do so through the standard processes for <span><a href="https://www.faa.gov/licenses_certificates/airmen_certification/update_address" aria-label="Link to airman certification" title="Airman Certificates" target="_blank">Airmen</a></span> or <span><a href="https://www.faa.gov/licenses_certificates/aircraft_certification/aircraft_registry/change_of_address" aria-label="Link to aircraft registration" title="Airman Registration" target="_blank">Aircraft Owners</a></span> in lieu of designating a U.S. Agent for Service.
                    </p>
                </div>
            </div>
            <div class="col-3 md:col-3">
                <div class="devkit-left-nav-link-wrapper w-auto" id="devkit-left-nav-link-wrapper">
                    <ul class="devkit-left-nav-links p-3" id="devkit-left-nav-links-resources">
                        <div class="devkit-nav-heading">Resource Links</div>
                        <li><a class="devkit-left-nav-link"
                            routerLink="/user-guide" aria-label="Link to User Guide"
                            title="User Guide">User Guide</a></li>
                        <!-- <li><a class="devkit-left-nav-link"
                            href="#" aria-label="Link to Policy"
                            title="Policy" target="_blank">Policy</a></li> -->
                        <li><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0"
                            (click)="faqsList()" aria-label="Link to FAQs"
                            title="FAQs">FAQ</a></li>
                        <li><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0"
                            (click)="contactUs()" aria-label="Link to Policy"
                            title="Policy">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grid mt-3 mb-3 text-sm">
            <div class="col-8">
                <div class="devkit-info-message-wrapper" role="dialog" aria-label="info-message" aria-describedby="info-message-body">
                    <div class="devkit-message-body-wrapper">
                      <p class="devkit-message-body" id="info-message-body">
                        <b>Note:</b> Due to security requirements, after 60 minutes of inactivity, you will be automatically logged off the application. You will be required to sign in again before resuming activity.
                      </p>
                    </div>
                  </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-8">
                <p-accordion class="text-sm">
                    <p-accordionTab header="Privacy Act Statement (5 U.S.C. § 552a, as amended)">
                        <dl>
                            <dt><b>Authority</b></dt>
                            <dd>Information solicited by United States Agent for Service (USAS) Portal is under the authorities of 49 U.S.C. 106(f), 106(g), 49 U.S.C. §§ 40113, 44101, 44109, 44110, 44702, 44703, 44709, 44807 and 14 C.F.R. Parts 3, 47, 61, 63, 65, 67 and/or 107.</dd>
                            <dt><b>Purpose</b></dt>
                            <dd>The USAS Portal is used by individuals who hold or apply for certificates, ratings, or authorizations issued under 14 CFR parts 47, 61, 63, 65, 67, or 107 and do not have a U.S. physical address of record on file with the FAA to designate a U.S. Agent for service. The USAS Portal may collect individual’s full name, email address, physical location or address, and mailing address. For those individuals that are airmen, the FAA may also collect the airman’s date of birth, certificate number, FAA tracking number, and applicant ID number. For individuals who hold or apply for aircraft registration certificates under part 47, the FAA may also collect the aircraft registration number, and aircraft make, model and serial number.</dd>
                            <dt><b>Routine Uses</b></dt>
                            <dd>The information collected by USAS Portal is shared  in accordance with the Privacy Act system of records notices (SORN)<a type="button" class="link-button"
                                href="https://www.govinfo.gov/content/pkg/FR-2023-08-09/pdf/2023-17073.pdf" aria-label="DOT Records"
                                title="DOT Records" target="_blank"> DOT/FAA 801 Aviation Registration Records, August 9, 2023, 88 FR 53951</a>and<a type="button" class="link-button"
                                href="https://www.federalregister.gov/documents/2024/06/10/2024-12595/privacy-act-of-1974-system-of-records" aria-label="DOT Records"
                                title="DOT Records" target="_blank"> DOT/FAA 847 ‐ Aviation Records on Individuals (89 75 FR 4895668849 ‐ June 10, 2024).</a>
                            </dd>
                        </dl>
                        <p>
                            <b>Disclosure:</b> Submission of this data is mandatory. Incomplete submission may result in delay of your request.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Paperwork Reduction Act Burden Statement">
                        <p>
                            A federal agency may not conduct or sponsor, and a person is not required to respond to, nor shall a person be subject to a penalty for failure to comply with a collection of information subject to the requirements of the Paperwork Reduction Act unless that collection of information displays a currently valid OMB Control Number.  The OMB Control Number for this information collection is 2120-0823.  Public reporting for this collection of information is estimated to be approximately 10 minutes per response, including the time for reviewing instructions, searching existing data sources, gathering and maintaining the data needed, and completing and reviewing the collection of information.
                        </p>
                        <p>
                            All responses to this collection of information are required to obtain or retain a benefit as provided in 14 C.F.R. Part 3, subpart C. The information collected is not for public dissemination and has the assurances of confidentiality provided under the following noted applicable SORNs. The information collected from individuals who hold or apply for certificates, ratings, or authorizations under 14 CFR parts 61, 63, 65, 67, or 107 will become part of the Privacy Act system of records DOT/FAA 847, General Aviation Records on Individuals, and afforded the protection offered under the Privacy Act and that particular system. The information collected from individuals who hold or apply for a certificate under 14 CFR part 47 will become part of the Privacy Act system of records DOT/FAA 801, and afforded the protection offered under that particular system.
                        </p>
                        <p>
                            Send comments regarding this burden estimate or any other aspect of this collection of information, including suggestions for reducing this burden to: Information Collection Clearance Officer, Federal Aviation Administration, 10101 Hillwood Parkway, Fort Worth, TX 76177-1524
                        </p>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</main>
