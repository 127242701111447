<main id="main-content" class="grid nested-grid">
    <div class="col-8 mt-4 px-6">
        <div class="grid mb-2" *ngIf="userData?.userIsActive !== 'Y'">
            <button type="button" class="secondary default" (click)="signin()">Go Back</button>
        </div>
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12">
                <h1>FAQs</h1>
            </div>
        </div>
        <div class="grid">
            <div class="col-12">
                <p-accordion class="text-sm">
                    <p-accordionTab header="Do I have to designate a U.S. agent for service?">
                        <p>
                            You are only required to designate a U.S. agent for service if you:
                        </p>
                        <ul>
                            <li>Do not have a U.S. physical address of record on file with the FAA;</li>
                            <li>Have a foreign address of record on file with the FAA; and</li>
                            <li>Hold or apply for a certificate, rating, or authorization under 14 CFR Part 47, 61, 63, 
                                65, 67, or 107.</li>
                        </ul>
                        <p>
                            You do not need to designate a U.S. agent if you have already updated your record of 
                            address with the FAA to provide a U.S. physical address. If you want to provide the FAA 
                            with a U.S. physical address, you may do so through the standard processes 
                            for <span><a href="https://www.faa.gov/licenses_certificates/airmen_certification/update_address" aria-label="Link to airman certification" title="Airman Certificates" target="_blank">Airmen</a></span> or <span><a href="https://www.faa.gov/licenses_certificates/aircraft_certification/aircraft_registry/change_of_address" aria-label="Link to aircraft registration" title="Airman Registration" target="_blank">Aircraft Owners</a></span> in lieu of designating a U.S. agent for service. 
                            Please be aware that a <span class="font-italic">U.S. physical address</span> is an address in the States of the United 
                            States, the District of Columbia, or any U.S. territory or possession, but excludes post 
                            office boxes, military post office boxes, mail drop boxes, and commercial addresses that 
                            are not also residential addresses.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Are all certificate holders, applicants, and aircraft owners required to designate a 
                    U.S. agent for service on USAS?">
                        <p>
                            Only the individuals who hold or apply for certificates issued under 14 CFR Part 47, 61, 
                            63, 65, 67, or 107 and who do not have a U.S. physical address of record on file with the 
                            FAA are required to designate a U.S. agent for service on USAS. The U.S. agent for 
                            service would receive service of FAA documents on behalf of the applicant or certificate 
                            holder.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="I have a U.S. physical address, why was I told to register a U.S. Agent for service?">
                        <p>
                            The FAA reviewed your address of record and determined that you do not have a U.S. 
                            physical address. If this is incorrect:
                        </p>
                        <ul>
                            <li>
                                Update your mailing address through the standard processes
                                for <span><a href="https://www.faa.gov/licenses_certificates/airmen_certification/update_address" aria-label="Link to airman certification" title="Airman Certificates" target="_blank">Airmen</a></span> or <span><a href="https://www.faa.gov/licenses_certificates/aircraft_certification/aircraft_registry/change_of_address" aria-label="Link to aircraft registration" title="Airman Registration" target="_blank">Aircraft Owners</a></span> in lieu of designating a U.S. agent for service.
                            </li>
                            <li>
                                Certificate holders, applicants, and aircraft owners who only hold a medical 
                                certificate can contact the Aerospace Medical Certification Division to update
                                your mailing address if you are doing so between applications for an airman 
                                medical certificate:
                            </li>
                            <address class="mt-2">
                                Federal Aviation Administration<br>
                                Aerospace Medical Certification Division, AAM-300<br>
                                CAMI, Bldg. 13<br>
                                PO Box 25082<br>
                                Oklahoma City, OK 73125<br>
                                Phone: (405) 954-4821
                            </address>
                        </ul>
                    </p-accordionTab>
                    <p-accordionTab header="What is the deadline for designating a U.S. agent for service?">
                        <p>
                            The compliance date for certificate, rating, or authorization applicants to designate a U.S. 
                            agent for service is April 2, 2025. The compliance date for current certificate, rating, or 
                            authorization holders is July 7, 2025.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Where do I designate my U.S. agent for service?">
                        <p>
                            Covered individuals will designate their U.S. agent on the USAS online portal. The 
                            USAS portal is available here: <span><a href="https://usas.faa.gov" aria-label="USAS Portal" title="USAS Portal">https://usas.faa.gov</a></span>. Please note that the USAS portal is currently under development and the go-live date will be no later than April 2, 2025.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Can I submit the U.S. agent for service information on my certificate application to 
                    the Airmen Certification Branch, or to any other part of the FAA?">
                        <p>
                            The only acceptable method to designate a U. S. agent for service is through the USAS 
                            portal, available here: <span><a href="https://usas.faa.gov" aria-label="USAS Portal" title="USAS Portal">https://usas.faa.gov</a></span>, as provided in Advisory Circular (AC) 3-
                            1. The FAA cannot enter any information on behalf of the airman to the USAS portal.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Can the FAA or another entity or individual designate a U.S. agent for service on my 
                    behalf?">
                        <p>
                            Only individuals covered under the FAA’s U.S. Agent for Service Rule may log in to 
                            USAS to designate their U.S. agent for service. The USAS portal is available here: 
                            <span><a href="https://usas.faa.gov" aria-label="USAS Portal" title="USAS Portal">https://usas.faa.gov</a></span>. Please note that the USAS portal is currently under development and 
                            the go-live date will be no later than April 2, 2025.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Is there any required information needed before designating a U.S. agent for service 
                    in USAS?">
                        <p>
                            Individuals using USAS to designate a U.S. agent for service should have the contact 
                            information for their U.S. agent. The FAA may request aircraft owners’ aircraft 
                            registration N-Number and Serial Number, which can be found through Aircraft Inquiry 
                            <span><a href="https://registry.faa.gov/aircraftinquiry/" aria-label="Aircraft Inquiry" title="Aircraft Inquiry" target="_blank">(Click here)</a></span>. The FAA may ask for all other certificate holders and applicants to provide their FAA Tracking Number (FTN) and date of birth (DOB). You may look up your FTN on your MedXPress Profile <span><a href="https://medxpress.faa.gov/MedXpress/Disclaimer.aspx" aria-label="MedXPress" title="MedXPress" target="_blank">(Click here)</a></span> or Integrated Airman Certification and Rating Application (IACRA) profile <span><a href="https://iacra.faa.gov/IACRA/Default.aspx" aria-label="IACRA" title="IACRA" target="_blank">(Click here)</a></span>. If you don’t have an FTN, you can register with IACRA prior to logging into USAS to create an FTN.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Can you login to USAS if you don’t have an FTN?">
                        <p>
                            Aircraft registered owners do not need a FAA Tracking Number (FTN) to log in to 
                            USAS, but they should have their aircraft registration N-Number and serial number to log
                            in to USAS. Aircraft owners can <span><a href="https://registry.faa.gov/aircraftinquiry" title="Registry" target="_blank">Click here</a></span> to look up your aircraft registration N-Number and Serial Number through Aircraft Inquiry.
                        </p>
                        <ul>
                            <li>Look up your FTN number in your MedXPress Profile by <span><a href="https://medxpress.faa.gov" title="MedXpress" target="_blank">Clicking here.</a></span></li>
                            <li>Look up your FTN number in your IACRA Profile by <span><a href="https://iacra.faa.gov" title="IACRA" target="_blank">Clicking here</a></span></li>
                            <li>If you currently hold an airmen certificate, you can look up your FTN number in your Airmen Services profile by <span><a href="https://amsrvs.registry.faa.gov/amsrvs/" title="amsrvs" target="_blank">Clicking here.</a></span>
                                An Airmen Services account can be created with the same link if you do not currently have one.</li>
                        </ul>
                        <p>
                            If you don’t have an FTN, please register with <span><a href="https://iacra.faa.gov" title="IACRA" target="_blank">IACRA</a></span> prior to logging into USAS to create an FTN account.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Are there fees for designating a U.S. agent for service with the FAA?">
                        <p>
                            The FAA does not charge any fees for registering your U.S. agent for service on USAS.
                            A U.S. agent for service may charge fees, but these fees are not controlled by the FAA. 
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="When does my U.S. agent for service designation on USAS become effective?">
                        <p>
                            A U.S. agent for service designation is effective once an individual designates their U.S. 
                            agent for service on USAS and the system sends an automated email to that individual 
                            confirming the designation was successful.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Where can I find the FAA’s U.S. agent for service regulations and guidance?">
                        <p>
                            The FAA’s U.S. agent for service regulations are found in 14 C.F.R. §§ 3.301-3.303. 
                            Details regarding FAA’s U.S. agent for service rule were published in the Federal 
                            Register via a <span><a href="https://www.federalregister.gov/documents/2023/06/12/2023-12124/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain" aria-label="Notice of Proposed Rulemaking" title="Notice of Proposed Rulemaking" target="_blank">Notice of Proposed Rulemaking</a></span> and a <span><a href="https://www.federalregister.gov/documents/2024/10/08/2024-22000/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain#:~:text=SUMMARY%3A,for%20service%20of%20FAA%20documents" aria-label="Final Rule" title="Final Rule" target="_blank">Final Rule</a></span>. The FAA extended the U.S. Agent for Service rule compliance date for applicants via a <span><a href="https://www.federalregister.gov/documents/2024/10/08/2024-22000/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain#:~:text=SUMMARY%3A,for%20service%20of%20FAA%20documents" aria-label="Final Rule" title="Final Rule" target="_blank">Final Rule</a></span> published in the Federal Register. The FAA also published guidance for compliance with the U.S. 
                            Agents for Service Rule in <span><a href="https://www.faa.gov/regulations_policies/advisory_circulars/index.cfm/go/document.information/documentID/1042728" aria-label="Advisory Circular (AC) 3-1" title="Advisory Circular (AC) 3-1">Advisory Circular (AC) 3-1</a></span>. AC 3-1 contains the link to FAA’s USAS portal for designating a U.S. agent. The USAS portal is available here: 
                            <span><a href="https://usas.faa.gov" aria-label="USAS Portal" title="USAS Portal">https://usas.faa.gov</a></span>. 
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Are U.S. agents for service vetted or approved by the FAA?">
                        <p>The FAA does not vet or approve U.S. agents for service.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Can U.S. agents for service register themselves as an individual’s agent on USAS or 
                    be registered by the FAA?">
                        <p>
                            U.S. agents for service do not register themselves as agents and FAA does not register 
                            U.S. agents for service. Individuals must designate their U.S. agent on USAS. The USAS 
                            portal is available here: <span><a href="https://usas.faa.gov" aria-label="USAS Portal" title="USAS Portal">https://usas.faa.gov</a></span>. Please note that the USAS portal is currently 
                            under development and the go-live date will be no later than April 2, 2025.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Who can be a U.S. agent for service?">
                        <p>
                            Under 14 CFR 3.302, a U.S. agent for service is an entity or an adult (individual who is 
                            18 or older) with a U.S. address who a certificate, rating, or authorization holder or 
                            applicant designates to receive FAA service on their behalf. In addition, a U.S. agent for 
                            service must have an address in the States of the United States, the District of Columbia, 
                            or any U.S. territory or possession that is not a post office box, military post office box, 
                            or a mail drop box. Under 14 CFR 3.303, a U.S. agent is required to receive FAA service 
                            of documents on behalf of the individual who designates them, and a U.S. agent must be 
                            competent to perform that responsibility. Examples of U.S. agents for service include, but 
                            are not limited to, an individual’s relative, friend, or associate.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="What are the U.S. agent address requirements?">
                        <p>
                            Under 14 CFR 3.302, a U.S. agent’s address must be in the States of the United States, 
                            the District of Columbia, or any U.S. territory or possession. If a U.S. agent is an entity, 
                            the U.S. address must be the U.S. agent’s office address. If the U.S. agent is an 
                            individual, the address must be the U.S. agent’s usual place of residence or, if applicable, 
                            the individual’s U.S. military office address. If the U.S. agent is serving as a U.S. agent in 
                            their official capacity with the military, the U.S. address may be a military office address. A U.S. agent address may not be a post office box, military post office box, or a mail 
                            drop box.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="What actions does a U.S. agent for service perform?">
                        <p>
                            A U.S. agent for service is responsible for timely transmitting all documents the FAA 
                            serves on the U.S. agent to the certificate, rating, or authorization holder or applicant that 
                            designated the U.S. agent. Transmittal methods from the U.S. agent to the individual 
                            might include, for example, scanning and emailing, faxing the documents, or sending the 
                            documents through expedited mail. The U.S. agent and the individual who designated the 
                            U.S. agent should agree in advance on what transmittal method would work best to 
                            ensure the U.S. agent’s timely transmittal of FAA correspondence to the individual.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="What will the consequences of non-compliance be for certificate holders and 
                    applicants?">
                        <p>
                            Pursuant to 14 CFR. 3.303, individuals who hold a certificate, rating, or authorization 
                            issued under 14 CFR Part 47, 61, 63, 65, 67, or 107, who fail to timely designate a U.S. 
                            agent for service and comply with the requirements under 14 CFR Part 3, subpart C, may 
                            not exercise the privileges of any certificate, rating, or authorization issued under 14 CFR
                            Part 47, 61, 63, 65, 67, or 107, and an individual aircraft owner's aircraft registration 
                            certificate will be considered ineffective. The FAA may take enforcement action against 
                            these individuals consistent with FAA Enforcement and Compliance Order 2150.3. The 
                            regulation also provides that after the deadline for compliance passes, applicants of any 
                            certificate, rating, or authorization issued under 14 CFR Part 47, 61, 63, 65, 67, or 107, 
                            who fail to designate a U.S. agent for service and comply with the requirements under 
                            14 CFR Part 3, subpart C shall not be issued a certificate, rating, or authorization under 
                            14 CFR 47, 61, 63, 65, 67, or 107.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Forgot your USAS portal password?">
                        <p>
                            Please <a href="https://usas.faa.gov/forgot-password" aria-label="forgot password" title="forgot-password" target="_blank">click here</a> to reset your password.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="What can I do if I am having trouble with the website?">
                        <p>
                            Please send an email to <span><a href="mailto:APP-AIT-USAS@FAA.GOV">APP-AIT-USAS&#64;FAA.GOV</a></span> for USAS system issues. Your 
                            email should include a detailed description of your issue. 
                            For trouble navigating USAS, please consult the USAS <a href="https://usas.faa.gov/user-guide" aria-label="user guide" title="user-guide" target="_blank">User Guide.</a>
                        </p>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</main>
