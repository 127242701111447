<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">EXTERNAL USER (AIRMAN) - DESIGNATE A U.S. AGENT</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">As an external user (with non-FAA email), once logged into USAS, you will be redirected to the ‘Manage U.S. Agent’ page and will see the ‘U.S. Agent Information’ section.
                </li>
                <li class="mb-3">Select the radio button for an ‘Airman/Operator’ and click ‘Next’.
                    <img class="w-6 block" src="../../../assets/images/image18.png">
                </li>
                <li class="mb-3">The ‘Add/Update U.S. Agent’ section with FAA Tracking Number (FTN) and Date of Birth (DOB) fields will be displayed. Enter your FTN number and DOB and click “Submit”.
                    <p><strong>Note:</strong> To return to the previous screen click on Cancel button.</p>
                    <img class="w-6 block mb-2" src="../../../assets/images/image19.png">
                </li>

                <li class="mb-3">As a first-time user logging into USAS, you will need to enter the FAA Tracking Number ‘FTN’ and date of birth ‘DOB’ in the corresponding fields. 
                    <p><strong>Note:</strong> If the correct FTN and DOB is entered and the FTN and DOB do not exist in the USAS Database, the following message will be displayed in the first attempt “Your FTN is not known to the USAS database. If you recently created your FTN, please click submit again to create a new U.S. Agent for Service.” </p>
                    <img class="w-6 block mb-2" src="../../../assets/images/image20-0.png">
                    <p>Enter the FTN and DOB entered in the previous attempt. You will see a blank ‘Manage U.S. Agent’ form is displayed. </p>
                </li>

                <li class="mb-3">A pop-up window ‘Manage U.S. Agent’ will be displayed. Select the ‘Type of U.S. Agent’ radio button:
                    <ul>
                        <li>Individual (selected by default) <strong>OR</strong></li>
                        <li>Service Agent Company</li>
                    </ul>
                    <p><strong>Note:</strong> The fields will dynamically change on the selection of the ‘Type of U.S. Agent’.</p>
                    <img class="w-6 block" src="../../../assets/images/image21.png">
                    <img class="w-6 block" src="../../../assets/images/image21-1.png">
                </li>
                <li class="mb-3">Fill all the required fields for the ‘Type of U.S. Agent’ selected. Select the radio button for address verification and click on ‘Confirm my selection’ button. Check the ‘I certify’ checkbox and click on ‘Submit’.
                    <p><strong>Notes:</strong></p>
                    <ul>
                        <li>Once you click on ‘Confirm my selection’ the address verification section will not be displayed.</li>
                        <li>Enter a U.S. physical address for the Agent. Do not enter P.O. box information in the address fields.</li>
                    </ul>
                    <img class="w-6 block" src="../../../assets/images/image21-2.png">
                </li>
                <li class="mb-3">A success message will be displayed. Your agent information will be saved successfully, and you will be redirected back to the ‘Manage U.S. Agent’ page. It will display the card with all the information as below:
                    <p><strong>Note:</strong> An email is triggered (to the email used for account creation in USAS) to the Airman upon agent designation and agent update.</p>
                    <img class="w-6 block" src="../../../assets/images/image21-3.png">
                    <img class="w-6 block" src="../../../assets/images/image21-4.png">
                </li>
                <li class="mb-3">
                    Click on ‘Edit or Update’ link in the right corner of the card to update the Agent Information.
                    <img class="w-6 block" src="../../../assets/images/image22.png">
                </li>
                <li class="mb-3">The prefilled ‘Manage U.S. Agent’ form will be displayed and available for any edits. Once the information is updated, click on ‘Submit’ to save.
                    <img class="w-6 block" src="../../../assets/images/image23.png">
                </li>
                <li class="mb-3">If the ‘Agent Type’ selected on the ‘Manage U.S. Agent’ form is a ‘Service Agent Company,’ the following information will need to be filled and submitted.
                    <img class="w-6 block" src="../../../assets/images/image24.png">
                </li>
                <li class="mb-3">The External user can also see the ‘Resource Links’ tab with the following sub menu items:
                    User Guide, FAQs and Contact Us.                    
                    <img class="w-6 block" src="../../../assets/images/image25.png">
                </li>
                <li class="mb-3">To return to ‘U.S. Agent Information’ page, click on ‘Click here’ button.
                    <img class="w-6 block" src="../../../assets/images/image26.png">
                </li>
                <li class="mb-3">You will be redirected to the U.S. Agent Information page, where you can continue to select the radio button for Airman/ Operator (to update the agent information) or designate the agent as an Aircraft Owner.
                    <img class="w-6 block" src="../../../assets/images/image26-1.png">
                </li>
            </ol>
        </div>
    </div>
</div>