<p-toast position="top-center"></p-toast>
<main id="main-content" class="grid nested-grid">
    <div class="col-8 mt-4 px-6" *ngIf="passwrodChanged && !isPasswordLinkExpired">
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12">
                <div class="devkit-success-message-wrapper" role="dialog" aria-labelledby="success-message-heading"
                    aria-describedby="success-message-body">
                    <div class="devkit-message-heading-wrapper">
                        <span class="fa fa-check-circle" aria-hidden="true" title="Success icon"></span>
                    </div>
                    <div class="devkit-message-body-wrapper">
                        <div class="devkit-message-heading" id="success-message-heading">
                            Password Changed Successfully!
                        </div>
                        <p class="devkit-message-body" id="success-message-body">
                            You have successfully changed your account password. Click on 'Go to sign in' button below to
                            sign in to your account
                        </p>
                        <div class="pb-4 pl-3">
                            <button class="primary default" (click)="goToDisclaimer()">Go to sign in</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-8 mt-4 px-6" *ngIf="!passwrodChanged && !isPasswordLinkExpired">
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12">
                <h1>Change Password</h1>
                <p>You must change the password for one of the following reasons:</p>
                <ul>
                    <li>You have requested to reset your account password</li>
                    <li>Your password is more than 365 days old</li>
                </ul>
            </div>
        </div>
        <form [formGroup]="userForm">
            <!--Setup Password Section-->
            <div class="grid px-2">
                <div class="lg:col-6 md:col-6 sm:col-12 bg-cyan-50 p-3 border-round-md border-300 border-1">
                    <h4>Password Requirements</h4>
                    <ul class="list-disc">
                        <li>Password is case sensitive</li>
                        <li>Password must contain between 12 to 24 characters</li>
                        <li>Password must begin with a letter</li>
                        <li>No character may be repeated in sequence</li>
                        <li>Password must include four types of characters:
                            <ul>
                                <li>English upper case letter (A through Z)</li>
                                <li>English lower case letter (a through z)</li>
                                <li>Number (0 through 9)</li>
                                <li>Only !, #, $, and % special characters are allowed</li>
                                <li>Spaces before, after or in between passwords are not allowed</li>
                            </ul>
                        </li>
                        <li>Password must not match previously used passwords</li>
                    </ul>
                </div>
            </div>

            <div class="grid px-2 my-2">
                <div class="form-grid grid">
                    <div class="form-group field col-12">
                        <p>Email ID</p>
                        <p class="font-semibold text-lg mt-0 mb-4">{{email}}</p>

                        <label for="newPassword">New Password<span class="asterik">*</span></label>
                        <p-password id="newPassword" formControlName="newPassword"
                            (keyup)="validatePassword('newPassword', 'newPassword')" [feedback]="false"
                            [toggleMask]="true" class="block border-noround" />
                        <div *ngIf="userForm.controls.newPassword.invalid && userForm.controls.newPassword.touched && userForm?.controls?.newPassword?.errors?.required"
                            class="alert danger-alert error-font">Password is required.</div>
                        <div *ngIf="userForm.controls.newPassword.invalid && userForm.controls.newPassword.touched && !userForm?.controls?.newPassword?.errors?.required"
                            class="alert danger-alert error-font">{{newPasswordError}}</div>
                    </div>
                </div>
            </div>

            <div class="grid px-2">
                <div class="form-grid grid">
                    <div class="form-group field col-12">
                        <label for="confirmPassword">Confirm Password<span class="asterik">*</span></label>
                        <p-password id="confirmPassword" formControlName="confirmPassword"
                            (keyup)="validatePassword('confirmPassword', 'confirmPassword')" [feedback]="false"
                            [toggleMask]="true" class="block border-noround" />
                        <div *ngIf="userForm.controls.confirmPassword.invalid && userForm.controls.confirmPassword.touched && userForm?.controls?.confirmPassword?.errors?.required"
                            class="alert danger-alert error-font">Confirm Password is required.</div>
                        <div *ngIf="userForm.controls.confirmPassword.invalid && userForm.controls.confirmPassword.touched && !userForm?.controls?.confirmPassword?.errors?.required"
                            class="alert danger-alert error-font">{{confirmPasswordError}}</div>
                    </div>
                </div>
            </div>

            <div>
                <button type="submit" [disabled]="!isFormValid" (click)="updatePassword()"
                    [ngClass]="!isFormValid ? 'disabled default': 'primary default'">Submit</button>
                <!-- <button class="secondary default">Cancel</button> -->
            </div>
        </form>
    </div>
    <div class="col-10 mt-4 px-6" *ngIf="isPasswordLinkExpired">
        <h1>FAA USAS Portal</h1>
        <div style="margin-bottom: 20px;">
            <p style="font-size: 20px;margin-top: 20px;">{{message}}</p>
        </div>
        <button type="button" class="primary default" (click)="signin()">Return to Sign In</button>
    </div>
</main>