import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter, withComponentInputBinding } from '@angular/router';
import { SecurityWarningComponent } from './appcomponents/security-warning/security-warning.component';
import { LandingPageComponent } from './appcomponents/landing-page/landing-page.component'
import { AuthGuardService } from './_auth/auth-guard.service';
import { LoginComponent } from './appcomponents/login/login.component';
import { AccessDeniedComponent } from '../app/appcomponents/access-denied/access-denied.component';
import { InternalLandingPageComponent } from './appcomponents/internal-landing-page/internal-landing-page.component';
import { InternalUserPageComponent } from './appcomponents/internal-user-page/internal-user-page.component'
import { AddInternalUserPageComponent } from './appcomponents/add-internal-user-page/add-internal-user-page.component';
import { UserAccountComponent } from './appcomponents/user-account/user-account.component';
import { ChangePasswordComponent } from './appcomponents/change-password/change-password.component';
import { ForgotPasswordComponent } from './appcomponents/forgot-password/forgot-password.component';
import { ConfirmVerifcationComponent } from './appcomponents/confirm-verifcation/confirm-verifcation.component';
import { FaqListComponent } from './appcomponents/faq-list/faq-list.component';
import { ContactUsComponent } from './appcomponents/contact-us/contact-us.component';
import { AccountInfoComponent } from './appcomponents/account-info/account-info.component';
import { LandingComponent } from './documentation/landing/landing.component';
import { InternalComponent } from './documentation/internal/internal.component';
import { ExternalComponent } from './documentation/external/external.component';
import { ExternalUserDesignateComponent } from './documentation/external-user-designate/external-user-designate.component';
import { ExternalUserAccountComponent } from './documentation/external-user-account/external-user-account.component';
import { UserGuideComponent } from './documentation/user-guide/user-guide.component';
import { SearchInternalUserComponent } from './documentation/search-internal-user/search-internal-user.component';
import { ManageInternalUserComponent } from './documentation/manage-internal-user/manage-internal-user.component';
import { ExternalAircraftDesignateComponent } from './documentation/external-aircraft-designate/external-aircraft-designate.component';

const routes: Routes = [
  { path: '', redirectTo: 'disclaimer', pathMatch: 'full' },
  { path: 'landing',  component: LandingPageComponent, canActivate: [AuthGuardService]},
  { path: 'disclaimer', component: SecurityWarningComponent },
  { path: 'signin', component: LoginComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  // { path: '**', redirectTo: '/landing', pathMatch: 'full' },
  { path: 'manage-users',  component: InternalUserPageComponent},
  { path: 'search', component: InternalLandingPageComponent, canActivate: [AuthGuardService] },
  { path: 'add-internal-user', component: AddInternalUserPageComponent },
  { path: 'user-account', component: UserAccountComponent },
  { path: 'reset-password', component: ChangePasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'confirm', component: ConfirmVerifcationComponent },
  { path: 'faq', component: FaqListComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'account-information', component: AccountInfoComponent},
  { path: 'documentation', component: LandingComponent},
  { path: 'documentation-internal', component: InternalComponent },
  { path: 'documentation-external-password', component: ExternalComponent },
  { path: 'documentation-external-designate', component: ExternalUserDesignateComponent },
  { path: 'documentation-external-userLogin', component: ExternalUserAccountComponent },
  { path: 'documentation-internal-search', component: SearchInternalUserComponent },
  { path: 'documentation-internal-manage-user', component: ManageInternalUserComponent },
  { path: 'user-guide', component: UserGuideComponent },
  { path: 'documentation-external-aircraft', component: ExternalAircraftDesignateComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [provideRouter(routes, 
    withComponentInputBinding() // 👈
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }