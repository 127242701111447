<main id="main-content" class="grid nested-grid">
    <div class="col-8 mt-4 px-6">
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12">
                <h1>Request to Reset Password</h1>
            </div>
        </div>
        <div *ngIf="!isAnswersCorrect">
            <div class="devkit-error-message-wrapper" role="dialog" aria-labelledby="error-message-heading"
                aria-describedby="error-message-body">
                <div class="devkit-message-heading-wrapper">
                    <span class="fa fa-times-circle" aria-hidden="true" title="error icon"></span>
                </div>
                <div class="devkit-message-body-wrapper">
                    <div class="devkit-message-heading" id="error-message-heading">
                        Error
                    </div>
                    <p class="devkit-message-body mt-0 pb-2" id="error-message-body">
                        Please correct the following error
                    </p>
                    <ul class="mt-2" *ngIf="showSectionTwo">
                        <li class="font-semibold">You must correctly answer any one of the security questions below, please check your response and try again or click on Cancel</li>                        
                        <li>If you need further assistance, please contact: <a type="button" href="mailto:APP-AIT-USAS@faa.gov"> APP-AIT-USAS&#64;faa.gov</a></li>
                    </ul>
                    <ul class="mt-2" *ngIf="showSectionOne">
                        <li class="font-semibold">The email you entered was not found in USAS</li>
                        <li>Please try again or click on Cancel</li>
                        <li>If you need further assistance, please contact:
                            <a type="button" href="mailto:APP-AIT-USAS@faa.gov"> APP-AIT-USAS&#64;faa.gov</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <form class="p-3 border-left-3 border-yellow-600" style="background-color: #8080801a" [formGroup]="resetForm">
            <!--Email Verify Section-->
            <div *ngIf="showSectionOne">
                <div class="grid">
                    <div class="lg:col-8 md:col-8 sm:col-12">
                        <h2>Step-1 Verify Email</h2>
                        <p class="text-sm">To reset your password, enter your email address in the box below and click
                            Next.</p>
                    </div>
                </div>
                <div class="grid px-2">
                    <div class="form-grid grid">
                        <div class="form-group field col-12">
                            <label for="email">Email<span class="asterik">*</span></label>
                            <input type="email" id="email" formControlName="email"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
                            <div *ngIf="resetForm.controls.email.invalid && resetForm.controls.email.touched && resetForm?.controls?.email?.errors?.required"
                                class="alert danger-alert error-font">Email is required.</div>
                            <div *ngIf="resetForm.controls.email.invalid && resetForm.controls.email.touched && !resetForm?.controls?.email?.errors?.required"
                                class="alert danger-alert error-font">Please enter a valid Email and Email field doesn’t
                                accept “&#64;faa.gov” email address.</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Security Questions Section-->
            <div *ngIf="showSectionTwo">
                <div class="grid">
                    <div class="lg:col-8 md:col-8 sm:col-12">
                        <h2>Step-2 Security Questions<span
                            class="asterik">*</span></h2>
                        <p class="text-sm">To verify your identity, you must correctly answer any 'ONE' of the questions below and click Submit.</p>
                    </div>
                </div>
                <div class="grid">
                    <div class="lg:col-8 md:col-8 sm:col-12">
                        <p class="font-semibold mb-0">Email</p>
                        <p class="text-lg">{{resetPasswordQuestions.email}}</p>
                    </div>
                </div>
                <div class="grid" *ngFor="let questions of securityQuestions">
                    <div class="lg:col-4 md:col-4 sm:col-6">
                        <div class="form-grid grid">
                            <div class="form-group field col-12">
                                <label for="question" class="block">{{questions.question}}</label>
                                <input type="question" id="{{questions.questionId}}"
                                    formControlName="{{questions.questionId}}"
                                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Confirmation Section-->
            <div *ngIf="showSectionThree">
                <div class="grid">
                    <div class="lg:col-8 md:col-8 sm:col-12">
                        <h2>Password Reset Confirmation</h2>
                        <p class="text-sm">
                            Your request to reset the password has been received. A temporary Reset Password link and
                            instructions for completing the password reset process will be sent to your email account.
                        </p>
                        <p>If you need further assistance, please contact:<a type="button" href="mailto:APP-AIT-USAS@faa.gov"> APP-AIT-USAS&#64;faa.gov</a></p>
                    </div>
                </div>
            </div>
            <div>
                <button type="submit" class="primary default" (click)="submitSectionOne()"
                    *ngIf="showSectionOne">Next</button>
                <button type="submit" class="primary default" (click)="submitSectionTwo()"
                    *ngIf="showSectionTwo">Submit</button>
                <button type="submit" class="primary default" (click)="signin()" *ngIf="showSectionThree">Return to Sign
                    in</button>
                <button class="secondary default" (click)="signin()">Cancel</button>
            </div>
        </form>
    </div>
</main>