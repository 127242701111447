<div class="flex flex-column px-8 py-5 gap-4">
  <i class="pi pi-user-plus m-auto" style="font-size: 2.5rem" aria-label="alert clock icon" role="img"></i>
</div>
<p class="text-center text-xl font-semibold">
  U.S. Agent is already associated
</p>
<p class="text-center">
  A U.S. Agent is already associated with the aircraft details you entered
</p>
<div class="flex justify-content-center gap-2">
  <button pButton label="Keep Existing U.S. Agent" class="primary default" (click)="closeModal(false)"></button>
  <button pButton label="Designate New U.S. Agent" class="primary default" (click)="closeModal(true)"></button>
  <button type="button" pButton label="Cancel" class="secondary default" (click)="closeModal()"></button>
</div>
