<div class="col-10 mt-4 px-6">
    <h1>FAA USAS Portal</h1>
    <div style="margin-bottom: 20px;" *ngIf="accountActivated">
        <p style="font-size: 20px;margin-top: 20px;">Your account is activated. Please log onto USAS Portal with your user credentials. </p>
    </div>
    <div style="margin-bottom: 20px;" *ngIf="isExpired && !accountActivated">
        <p [innerHTML]="message" style="font-size: 20px;margin-top: 20px;">{{message}}</p>
    </div>
    <div style="margin-bottom: 20px;" *ngIf="!accountActivated && !isExpired">
        <p style="font-size: 20px;margin-top: 20px;">The application has encountered an unknown error. Please try again later. </p>
    </div>
    <button type="button" class="primary default" (click)="signin()">Return to Sign In</button>
</div>
