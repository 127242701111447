<div class="grid mb-2 px-6 mt-4">
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">EXTERNAL USER (AIRCRAFT OWNER) - DESIGNATE A U.S. AGENT</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">As an external user (with non- FAA email), once logged into USAS, you will be redirected to the ‘Manage U.S. Agent’ page and will see the ‘U.S. Agent Information’ section. Select the radio button for ‘Aircraft Owner’ and click ‘Next’.
                    <img class="w-6 block" src="../../../assets/images/aircraft1.png">
                </li>
                <li class="mb-3">The ‘Add/Update U.S. Agent’ section with N Number and Serial Number fields will be displayed. Enter your N Number and Serial Number and click on ‘Submit’.
                    <p><strong>Note:</strong> To return to the previous screen click on Cancel button.</p>
                    <img class="w-6 block mb-2" src="../../../assets/images/aircraft2.png">
                </li>

                <li class="mb-3">If it is your first-time logging into USAS, you will need to enter the aircraft’s ‘N Number’ and ‘Serial Number’ in the corresponding fields and click on Submit. If the N Number and Serial Number you entered is not in the USAS DB, you will see the following message in the first try: “The Aircraft's N Number and Serial Number is not known to the USAS database. Please check the N Number and Serial Number and submit again to create a new U.S. Agent for Service.”  Enter the correct N Number and Serial Number again to see a blank ‘Manage U.S. Agent’ form.
                    <img class="w-6 block mb-2" src="../../../assets/images/aircraft3.png">
                </li>

                <li class="mb-3">A pop-up window ‘Manage U.S. Agent’ will be displayed. Fill in the agent details either for an agent type -Individual or a Service Agent Company.
                    <img class="w-6 block" src="../../../assets/images/aircraft4.png">
                </li>
                <li>Select the ‘Type of U.S. Agent’ radio button:
                    <ul>
                        <li>Individual (selected by default) <strong>OR</strong></li>
                        <li>Service Agent Company</li>
                    </ul>
                    <p><strong>Note:</strong> The fields will dynamically change on the selection of the ‘Type of U.S. Agent’.</p>
                </li>
                <li class="mb-3">Fill all the required fields for the ‘Type of U.S. Agent’ selected. Select the radio button for address verification and click on ‘Confirm my selection’ button. Check the ‘I certify’ checkbox and click on ‘Submit’.
                    <p><strong>Notes:</strong></p>
                    <ul>
                        <li>Once you click on ‘Confirm my selection’ the address verification section will not be displayed.</li>
                        <li>Enter a U.S. physical address for the Agent. Do not enter P.O. box information in the address fields.</li>
                    </ul>
                    <img class="w-6 block" src="../../../assets/images/image21-2.png">
                </li>
                <li class="mb-3">Once the agent is designated, a success message ‘U.S. Agent saved successfully’ will be displayed, and the user will be redirected to the ‘Manage U.S. Agent’ page. The display card with the AR Owner Name and the Aircraft details will be displayed.
                    <p><strong>Note:</strong> Once the Agent is designated, an email will also be sent to the AR Owner using the email address that was registered with USAS (second screenshot below).</p>
                    <img class="w-6 block" style="margin-bottom: 15px;" src="../../../assets/images/aircraft5.png">
                    <img class="w-6 block" src="../../../assets/images/aircraft6.png">
                </li>
                <li class="mb-3">
                    To see the U.S. Agent Details, click on the ‘View Agent Details’ link in the upper right corner of the display card.
                    <img class="w-6 block" src="../../../assets/images/aircraft7.png">
                </li>
                <li class="mb-3">Click on ‘Click here’ button on top of the display card to return to ‘U.S. Agent Information’ page and to designate an agent for another aircraft.
                    <img class="w-6 block" src="../../../assets/images/aircraft8.png">
                </li>
                <li class="mb-3">You will be redirected back to the ‘Manage U.S. Agent’ page, where you can select the radio button for ‘Aircraft Owner’ and click on ‘Next’.
                    <img class="w-6 block" src="../../../assets/images/aircraft9.png">
                </li>
                <li class="mb-3">If you enter the N Number and the Serial Number of the Aircraft for which you have previously designated a U.S. Agent, you will see the option to select either of the buttons below:   
                    <ul>
                        <li>Keep Existing U.S. Agent</li>
                        <li>Designate New U.S. Agent</li>
                        <li>Cancel</li>
                    </ul>                
                    <img class="w-6 block" src="../../../assets/images/aircraft10.png">
                </li>
                <li class="mb-3">If you select the button to ‘Keep existing U.S. Agent’, you will be redirected to the ‘Manage U.S. Agent’ page and will see the display card with the existing aircraft details. Click on ‘View Agent details’ to see the details of the designated Agent. 
                    <img class="w-6 block" src="../../../assets/images/aircraft11.png">
                </li>
                <li class="mb-3">If you select the button to ‘Designate New U.S. Agent’ for the same aircraft, you will see a blank ‘Manage U.S. Agent’ form which you can fill to designate a new U.S. Agent. 
                    <img class="w-6 block" src="../../../assets/images/aircraft12.png">
                </li>
                <li class="mb-3">If you click on ‘Cancel’ button, the ‘Designate U.S. Agent’ window will be closed, and you will stay on the screen below. The values for the N Number and Serial Number fields previously entered will be cleared. 
                    <img class="w-6 block" src="../../../assets/images/aircraft13.png">
                </li>
                <li class="mb-3">To designate an agent for a New Aircraft, you will need to do the following:
                    <ul>
                        <li>Click on ‘Add New Aircraft’.</li>
                        <li>Enter the N Number and the Serial Number for the new aircraft.</li>
                        <li>Click on Submit.</li>
                        <li>On the new ‘Manage U.S. Agent’ form enter the agent details and click on submit.</li>
                        <li>Once the agent is designated, you will see a success message and the display card for the new aircraft. </li>
                        <li>If you click on ‘View Agent Details’ for the new aircraft, you will be able to see the agent details for the designated agent for the new aircraft.</li>
                    </ul>
                    <img class="w-6 block" src="../../../assets/images/aircraft14.png">
                </li>
                <li>Click on ‘Resource Links’ to access the sub menu items as in the screenshot below:
                    <img class="w-6 block" src="../../../assets/images/aircraft15.png">
                </li>
            </ol>
        </div>
    </div>
</div>