<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">Internal User Login Functions</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">Launch the
                    following URL - <a href="https://usas.faa.gov/" target="_blank">https://usas.faa.gov/</a></li>
                <li class="mb-3">You will see the disclaimer page for USAS. Click on the ‘Accept’ button.
                    <img class="w-6 block" src="../../../assets/images/internal_image1.png">
                </li>
                <li class="mb-3">You will be redirected to the USAS Login page as in the screenshot below:
                    <img class="w-6 block" src="../../../assets/images/image1.png">
                </li>
                <li class="mb-3">Click on ‘FAA User Sign in’ button.</li>
                <li class="mb-3">You will be redirected to the ‘Sign in using MyAccess’ page. Click on ‘Sign in with PIV
                    / CAC card’ button.
                    <img class="w-6 block" src="../../../assets/images/image28.png">
                </li>
                <li class="mb-3">You will see a pop-up window to ‘Select a certificate’. Select the appropriate
                    certificate and click ‘OK’.
                    <img class="w-6 block" src="../../../assets/images/internal_image2.png">
                </li>

                <li class="mb-3">Enter your PIN in the ‘ActiveClient Login’ popup window and click ‘OK’.
                    <img class="w-6 block" src="../../../assets/images/image30.png">
                </li>
                <li class="mb-3">Once authenticated, you will be re-directed to the Internal landing page (Search
                    Airman / Operator / Aircraft Registered Owner page) for USAS and should be able to see the following
                    radio buttons on the Search page together with the additonal tabs:
                    <ul>
                        <li class="mb-3">Search
                            (Internal User Role)
                            <ul>
                                <li>Airman / Operator (Radio button)</li>
                                <li>Aircraft Registered Owner (Radio button)</li>
                            </ul>
                        </li>
                        <li class="mb-3">Manage FAA
                            Users (Internal Admin Role only)</li>
                        <li class="mb-3">Resource
                            Links</li>
                    </ul>

                    <img class="w-6 block" src="../../../assets/images/image31.png">
                </li>
            </ol>
        </div>
    </div>
</div>