<div *ngIf="isLoading">
  <app-usas-loading></app-usas-loading>
</div>
<p-toast position="top-center"></p-toast>

<main id="main-content">
  <div class="mt-4 px-6" *ngIf="!showEditMode && !showAREditMode ">
    <h1>U.S. Agent Information</h1>
    <div style="min-height: 360px;">
      <div class="grid">
        <div class="col-10">
          <div [formGroup]="roleSelection" class="border-round-lg p-4 h-auto bg-dark-cream border-top-3 border-yellow-600 mb-3">
            <div class="form-items-wrapper">
              <div class="form-item-wrapper" style="margin-right: 30px;">
                <h2>Please select the appropriate radio button to designate/update the U.S. Agent for an Airman / Operator OR
                  Aircraft Owner.</h2>
                <div *ngFor="let category of categories" class="field-checkbox">
                  <p-radioButton [inputId]="category.key" [value]="category" formControlName="selectedCategory" />
                  <label [for]="category.key" class="ml-2">
                    {{ category.name }}
                  </label>
                </div>
              </div>
              <div class="col-4 bg-cover bg-center border-round"
                style="background-image: url('https://www.faa.gov/sites/faa.gov/files/styles/faa_home_priorities_card/public/2023-02/priorities-equity.jpg?itok=KvASWt28');">
              </div>
            </div>
            <div>
              <button type="submit" class="primary default" (click)=showChoice()>Next</button>
            </div>
          </div>
          <div *ngIf="showAROwnerSection" class="border-round-lg p-4 h-auto bg-dark-cream border-top-3 border-yellow-600">
            <div class="form-items-wrapper">
              <div class="form-item-wrapper" style="margin-right: 30px;">
                <h2>Add/Update U.S. Agent</h2>
                <div>
                  <p *ngIf="!isArError">The current FAA rule requires individuals with foreign addresses to designate a U.S. Agent for
                    service of FAA documents.
                    For further information on rulemaking, access <a type="button" target="_blank" class="link-button ml-0"
                      href="https://www.federalregister.gov/documents/2024/10/08/2024-22000/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain"
                      aria-label="rulemaking website" title="Federal Register">U.S Agents for Service Final Rule.</a></p>
                  <p *ngIf="!isArError" class="font-bold">The FAA uses an Aircraft's N Number and Serial number to uniquely identify each person taking actions on many FAA systems. The aircraft already has one of these unique identifiers assigned. You must know the Aircraft's N Number and Serial number to assign a U.S. Agent for Service.</p>
                  <p *ngIf="isArError">The FAA uses Aircraft's N Number and Serial Number to uniquely identify each person taking actions on many FAA systems. You must know the Aircraft's N Number and Serial Number to assign a U.S. Agent for Service.</p>
                  <p class="text-sm mt-5">Please enter Aircraft's N Number and Serial Number below to designate U.S.
                    Agent</p>
                </div>
                <p style="color: #c32026" id="errorMessage" class="text-sm pr-3" *ngIf="isArError">
                  <span><i class="fa-solid fa-triangle-exclamation" style="color: #c32026;"></i></span>
                  {{errorMessage}}
                </p>
                <div class="form-items-wrapper" [formGroup]="ARSearch">
                  <div class="form-item-wrapper" style="margin-right: 30px;">
                    <label for="nNumber">N Number<span class="asterik">*</span><span class="fas fa-info-circle fa-sm ml-1" style="font-size: 1rem" pTooltip="Value must start with a number, be alphanumeric, and exactly 5 characters long (e.g., 1234B, 989AB)."></span></label>
                    <input pInputText id="nNumber" type="text" formControlName="nNumber" maxlength="5"
                      class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"
                      style="height: 34px;" required />
                    <div
                      *ngIf="ARSearch?.get('nNumber')?.invalid  && ARSearch?.get('nNumber')?.errors['required'] && ARSearch?.get('nNumber')?.touched"
                      class="alert danger-alert error-font">N Number is required. </div>
                    <div class="alert danger-alert error-font"
                      *ngIf="ARSearch?.get('nNumber')?.errors && ARSearch?.get('nNumber')?.errors['pattern']">
                      Please enter valid N Number.
                    </div>
                  </div>
                  <div class="form-item-wrapper">
                    <label for="nNumber">Serial Number<span class="asterik">*</span><span class="fas fa-info-circle fa-sm ml-1" style="font-size: 1rem" pTooltip="Alphanumeric, up to 30 characters and may include a hyphen (e.g., 32R-7680891)."></span></label>
                    <input pInputText id="serialNumber" type="text" formControlName="serialNumber" maxlength="30"
                      class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"
                      style="height: 34px;" required />
                    <div
                      *ngIf="ARSearch?.get('serialNumber')?.invalid  && ARSearch?.get('serialNumber')?.errors['required'] && ARSearch?.get('serialNumber')?.touched"
                      class="alert danger-alert error-font">Serial Number is required. </div>
                    <div class="alert danger-alert error-font"
                      *ngIf="ARSearch?.get('serialNumber')?.errors && ARSearch?.get('serialNumber')?.errors['pattern']">
                      Please enter valid Serial Number.
                    </div>
                  </div>
                </div>

                <button type="submit" class="primary default" (click)="handleARSubmit()">Submit</button>
                <button class="secondary default" (click)="restArForm()">Clear</button>
                <button type="submit" class="secondary default" (click)="addNewAgent()">Cancel</button>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div *ngIf="showFTNSection" class="border-round-lg p-4 h-auto bg-dark-cream border-top-3 border-yellow-600">
            <h2>Add/Update U.S. Agent</h2>
            <div *ngIf="!isError">
              <p>The current FAA rule requires individuals with foreign addresses to designate a U.S. Agent for service
                of FAA documents.
                For further information on rulemaking, access  <a type="button" target="_blank" class="link-button ml-0"
                  href="https://www.federalregister.gov/documents/2024/10/08/2024-22000/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain"
                  aria-label="rulemaking website" title="Federal Register">U.S Agents for Service Final Rule.</a></p>
            </div>
            <div>
              <p class="font-bold">The FAA uses a FAA Tracking number (FTN) to uniquely identify each person taking actions on many FAA systems. You may already have one of these unique identifiers assigned to you. <span class="text-yellow-700">You must know your unique identifier and date of birth to assign a U.S. Agent for Service.</span></p>
            </div>
            <p class="text-sm mt-5" *ngIf="!isError">Please enter FTN and DOB to designate/update your U.S. Agent</p>

            <p style="color: #c32026" id="errorMessage" class="text-sm pr-3" *ngIf="isError">
              <span><i class="fa-solid fa-triangle-exclamation" style="color: #c32026;"></i></span>
              {{errorMsg}}
            </p>

            <div class="form-items-wrapper" [formGroup]="ftnSearch">
              <div class="form-item-wrapper" style="margin-right: 30px;">
                <label for="ftnNumber">FTN<span class="asterik">*</span><span class="fas fa-info-circle fa-sm ml-1" style="font-size: 1rem" pTooltip="Value must start with a letter, followed by exactly 7 digits (e.g., A8766787, C5656550)."></span></label>
                <input pInputText id="ftnNumber" type="text" maxLength="8" formControlName="ftnNumber"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"
                  style="height: 34px;" required />
                <div
                  *ngIf="ftnSearch?.get('ftnNumber')?.invalid  && ftnSearch?.get('ftnNumber')?.errors['required'] && ftnSearch?.get('ftnNumber')?.touched"
                  class="alert danger-alert error-font">FTN is required. </div>
                <div class="alert danger-alert error-font"
                  *ngIf="ftnSearch?.get('ftnNumber')?.errors && ftnSearch?.get('ftnNumber')?.errors['pattern']">
                  Please enter valid FTN.
                </div>
              </div>
              <div class="form-item-wrapper">
                <label for="dobEntry" style="display: block;">DOB<span class="asterik">*</span></label>
                <p-calendar placeholder="mm/dd/yyyy" formControlName="dob" [iconDisplay]="'input'" [showIcon]="true"
                  [selectOtherMonths]="true" [maxDate]="maxdobDate" />
                <div
                  *ngIf="ftnSearch?.get('dob')?.invalid  && ftnSearch?.get('dob')?.errors['required'] && ftnSearch?.get('dob')?.touched"
                  class="alert danger-alert error-font">DOB is required. </div>
              </div>
            </div>
            <div>
              <button type="submit" class="primary default" (click)="handleFtnSubmit()">Submit</button>
              <button class="secondary default" (click)="restFtnForm()">Clear</button>
              <button type="submit" class="secondary default" (click)="addNewAgent()">Cancel</button>
            </div>

          </div>
          <div class="devkit-info-message-wrapper mt-3" role="dialog" aria-label="info-message"
            aria-describedby="info-message-body" *ngIf="showFTNSection">
            <div class="devkit-message-body-wrapper">
              <div class="devkit-message-body" id="info-message-body">
                <b>Note:</b> Visit<span><a type="button" target="_blank" class="link-button" href="https://iacra.faa.gov/" aria-label="iacra website"
                  title="IACRA Website">iacra.faa.gov</a></span>to either register for a new FTN number or to look up the
                FTN on your account profile using the below links:
                <ul>
                  <li>MedXPress - <span><a class="font-medium no-underline link-button cursor-pointer ml-0"
                      href="http://medxpress.faa.gov/" aria-label="Redirect to MedXPress"
                      title="Redirect to MedXPress" target="_blank">Click here</a></span>to look up your FTN number in your MedXPress Profile.
                  </li>
                  <li class="mt-2">IACRA - <span><a class="font-medium no-underline link-button cursor-pointer ml-0"
                      href="https://iacra.faa.gov/" aria-label="Redirect to IACRA"
                      title="Redirect to IACRA" target="_blank">Click here</a></span>to look up your FTN number in your IACRA Profile.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="devkit-info-message-wrapper mt-3" role="dialog" aria-label="info-message"
            aria-describedby="info-message-body" *ngIf="showAROwnerSection">
            <div class="devkit-message-body-wrapper">
              <div class="devkit-message-body" id="info-message-body">
                <b>Note:</b> Visit<span><a type="button" class="link-button" target="_blank"
                    href="https://registry.faa.gov/aircraftinquiry/"
                    aria-label="aircraft registry" title="Aircraft Registry">Aircraft Registry</a></span>to look up the
                Aircraft's N Number and Serial Number and then return here to designate/update/view the U.S. Agent.
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-4 bg-cover bg-center border-round"
          style="background-image: url('https://www.faa.gov/sites/faa.gov/files/styles/faa_home_priorities_card/public/2023-02/priorities-equity.jpg?itok=KvASWt28'); min-height: 360px;">
        </div> -->
      </div>
    </div>
  </div>

  <!--Aicraft Card-->
  <div class="mt-4 px-5" *ngIf="showAREditMode || showEditMode">
    <h1>Manage U.S. Agent</h1>
  </div>
  <div class="grid" *ngIf="showAREditMode || showEditMode">
    <div class="col-auto mt-2 px-6">
      <div class="devkit-info-message-wrapper" role="dialog" aria-label="info-message" aria-describedby="info-message-body">
        <div class="devkit-message-body-wrapper">
          <p class="devkit-message-body" id="info-message-body">
            To return to 'U.S. Agent Information' page
            <span><button type="submit" class="primary default" (click)="addNewAgent()">Click here</button></span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="grid col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12 mt-1 px-6 " style="display: flex;"
    *ngIf="showAREditMode">
    <div class="col-4 border-round-lg p-4 h-auto"
      style="background-color: #8080801a;margin-right: 30px;margin-bottom: 30px;" *ngFor="let arOwner of arOwnerList">
      <div class="grid border-bottom-3 border-yellow-600">
        <div class="col-6 xl:col-6 lg:col-6 md:col-6">
          <div class="text-sm font-semibold mb-0">
            AR Owner
          </div>
          <div class="text-xl mt-0 capitalize">{{arOwner.arOwnerName}}</div>
        </div>
        <div class="col-6 xl:col-6 lg:col-6 md:col-6 justify-content-end flex-grow-1">
          <a class="edit-link text-sm text-color-primary" (click)="showwAgent(arOwner.nNumber)"
            style="float: right;">View Agent Details</a>
          <br>
          <p class="m-t-0 text-xs" style="float: right;">Last updated: {{arOwner.lastUpdateDate}}</p>
        </div>
      </div>
      <div class="grid mt-2">
        <div class="col-12">
          <h2 class="text-lg font-semibold text-blue-800 mt-0">Aircraft Details</h2>
          <div class="grid">
            <div class="col-4 md:col-4 sm:col-12">
              <div class="text-sm font-semibold mb-0">N Number</div>
              <div>{{arOwner.nNumber}}</div>
            </div>
            <div class="col-6 md:col-6 sm:col-12">
              <div class="text-sm font-semibold mb-0">Serial Number</div>
              <div>{{arOwner.serialNumber}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid pb-5">
    <div class="col-10 xl:col-6 lg:col-10 md:col-10 sm:col-12 mt-4 px-6" *ngIf="showEditMode">
      <div class="border-round-lg p-4 h-auto" style="background-color: #8080801a">
        <div class="grid border-bottom-3 border-yellow-600">
          <div class="col-6 xl:col-6 lg:col-6 md:col-6">
            <div class="text-sm font-semibold mb-0">
              Airman / Operator
            </div>
            <div class="text-xl mt-0 capitalize">{{userName}}</div>
          </div>
          <div class="col-6 xl:col-6 lg:col-6 md:col-6 justify-content-end flex-grow-1">
            <a class="edit-link text-sm text-color-primary" (click)="addAgent()" style="float: right;"><i
                class="fa-solid fa-pencil mr-1"></i>Edit or Update</a>
            <br>
            <p class="m-t-0 text-sm" style="float: right;">Last updated: {{agentDetails.lastUpdateDate}}</p>
          </div>
        </div>
        <div class="grid mt-2">
          <div class="col-12">
            <h2 *ngIf="agentDetails?.isAgentIndividual=== 'Y'" class="text-lg font-semibold text-blue-800 mt-0">Individual
              U.S. Agent Details</h2>
            <h2 *ngIf="agentDetails?.isAgentIndividual === 'N'" class="text-lg font-semibold text-blue-800 mt-0">Service
              Agent Company Details</h2>

            <div *ngIf="agentDetails?.isAgentIndividual=== 'Y'" class="text-sm font-semibold mb-0">Individual U.S. Agent
            </div>
            <div *ngIf="agentDetails?.isAgentIndividual === 'N'" class="text-sm font-semibold mb-0">Service Agent Company
            </div>
            <div *ngIf="agentDetails?.isAgentIndividual=== 'Y'">{{agentDetails?.firstName}} {{agentDetails?.middleName}}
              {{agentDetails?.lastName}} {{agentDetails?.suffix}}</div>
            <div *ngIf="agentDetails?.isAgentIndividual=== 'N'"> {{agentDetails?.agentCompanyName}}</div>
          </div>
        </div>
        <div class="grid" *ngIf="agentDetails.isAgentMilitaryEmp=== 'Y' && agentDetails?.isAgentIndividual=== 'Y'">
          <div class="col-12">
            <div class="text-sm font-semibold mb-0">Military</div>
            <div>Yes</div>
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <div class="text-sm font-semibold mb-0">Address</div>
            <div>{{agentDetails?.addressLine1}}</div>
            <div>{{agentDetails?.addressline2}}</div>
            <div>{{agentDetails?.city}}, {{agentDetails?.stateCd}} {{agentDetails?.zipCode}}<span
                *ngIf="agentDetails?.zip4Code">-{{agentDetails?.zip4Code}}</span>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col-4 md:col-4 sm:col-12">
            <div class="text-sm font-semibold mb-0">Phone</div>
            <div *ngIf="agentDetails?.phone">{{ agentDetails?.phone}}</div>
          </div>
          <div class="col-6 md:col-6 sm:col-12">
            <div class="text-sm font-semibold mb-0">Fax</div>
            <div>{{agentDetails?.fax}}</div>
          </div>
        </div>
        <div class="grid">
          <div class="col-6 md:col-6 sm:col-12">
            <div class="text-sm font-semibold mb-0">E-mail</div>
            <div>{{agentDetails?.email}}</div>
          </div>
        </div>
        <div *ngIf="agentDetails?.isAgentIndividual=== 'N'">
          <div class="grid border-top-2 mt-2">
            <div class="col-12">
              <h2 class="text-lg font-semibold mb-0 text-blue-800">Service Agent Company Representative</h2>
            </div>
          </div>
          <div class="grid">
            <div class="col-12">
              <div class="text-sm font-semibold mb-0">Name</div>
              <div class="capitalize">{{agentDetails?.firstName}} {{agentDetails?.middleName}} {{agentDetails?.lastName}}
                {{agentDetails?.suffix}}</div>
            </div>
          </div>
          <div class="grid">
            <div class="col-4 md:col-4 sm:col-12">
              <div class="text-sm font-semibold mb-0">Phone</div>
              <div *ngIf="agentDetails?.pocPhone">{{ agentDetails?.pocPhone }}</div>
            </div>
            <div class="col-6 md:col-6 sm:col-12">
              <div class="text-sm font-semibold mb-0">Fax</div>
              <div>{{agentDetails?.pocFax}}</div>
            </div>
          </div>
          <div class="grid">
            <div class="col-6 md:col-6 sm:col-12">
              <div class="text-sm font-semibold mb-0">E-mail</div>
              <div>{{agentDetails?.pocEmail}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
