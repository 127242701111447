import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_auth/auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  isInternalAdmin: boolean;
  isExternalUser: boolean;
  isInternalUser: boolean;
  constructor(private router: Router, private authService: AuthService){

  }
  ngOnInit(): void {
    this.checkInternalAccess();
  }
   checkInternalAccess() {
    document.getElementById('external-content')?.classList.remove('hidden');
    document.getElementById('internal-content')?.classList.add('hidden');
    this.isInternalAdmin = this.authService.isInternalAdminUser();
    this.isExternalUser = this.authService.isExternalUser();
    this.isInternalUser = this.authService.isInternalUser();
    if(this.isInternalAdmin || this.isInternalUser ){
      document.getElementById('internal-content')?.classList.remove('hidden');
      document.getElementById('external-content')?.classList.add('hidden');
    }else if(this.isExternalUser){
      document.getElementById('external-content')?.classList.remove('hidden');
      document.getElementById('internal-content')?.classList.add('hidden');
    }
    // var img = new Image();
    // var timeout = setTimeout(function() {
    //     document.getElementById('external-content')?.classList.remove('hidden');
    //     document.getElementById('internal-content')?.classList.add('hidden');
    // }, 3000);

    // img.src = 'https://dotnet.dot.gov/themes/custom/intranet/logo_dotnet.svg'; // Internal image URL

    // img.onload = function() {
    //     clearTimeout(timeout);
    //     console.log('internal');
    //     document.getElementById('internal-content')?.classList.remove('hidden');
    //     document.getElementById('external-content')?.classList.add('hidden');
    // };

    // img.onerror = function() {
    //     clearTimeout(timeout);
    //     console.log('external');
    //     document.getElementById('external-content')?.classList.remove('hidden');
    //     document.getElementById('internal-content')?.classList.add('hidden');
    // };
}
navigateToInternal(){
this.router.navigate(["/documentation-internal"])
}
navigateToExternalUserLogin(){
  this.router.navigate(["/documentation-external-userLogin"])
}
navigateToExternalPassword(){
  this.router.navigate(["/documentation-external-password"])
}
navigateToExternalUserDesignate(){
  this.router.navigate(["/documentation-external-designate"])
}
navigateToInternalUserSearch(){
  this.router.navigate(["/documentation-internal-search"])
}
navigateToInternalUserManage(){
  this.router.navigate(["/documentation-internal-manage-user"])
}
nativateToAcircraftDesignate(){
  this.router.navigate(["/documentation-external-aircraft"])
}
}
