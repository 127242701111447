import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from "@angular/core";
import { AuthService } from "../../_auth/auth.service";
import { Router } from '@angular/router';
import { UserDetailsService } from '../../shared/services/user-details.service';
import { MenuItem } from "primeng/api/menuitem";
@Component({
  selector: "faa-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() headerLandingFlag?:boolean;  
  intenalNavBar:boolean = false;
  validToken = false;
  userName: string;
  userData:any
  isInternalAdmin: boolean;
  isExternalUser: boolean;
  isInternalUser: boolean;

  items: MenuItem[] | undefined;
  
  
 constructor(private authService: AuthService, public router: Router, private userDetailsService: UserDetailsService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.userData = this.authService.getUserData()
    this.validToken = this.authService.isValidJWT();
    if(this.userData && this.validToken){
      this.isInternalAdmin = this.authService.isInternalAdminUser();
      this.isExternalUser = this.authService.isExternalUser();
      this.isInternalUser = this.authService.isInternalUser();
      this.userName = this.userData.firstName+ ' ' + this.userData.lastName
    }

    this.items = [
      {
          items: [
              {
                  label: 'User Guide',
                  icon: 'pi pi-book',
                  url: '/user-guide' //replace with link
              },
              {
                  label: 'FAQs',
                  icon: 'pi pi-info-circle',
                  route: '/faq'
              },
              {
                  label: 'Contact Us',
                  icon: 'pi pi-question-circle',
                  route: '/contact-us'
              },
          ]
      }
    ];

  }
  @HostListener('window:updateHeader', [ '$event' ])
  unloadHandler(event:Event) {
    this.userData = this.authService.getUserData()
    this.validToken = this.authService.isValidJWT();
    if(this.userData && this.validToken && sessionStorage.getItem('userSignin')){
      this.isInternalAdmin = this.authService.isInternalAdminUser();
      this.isExternalUser = this.authService.isExternalUser();
      this.isInternalUser = this.authService.isInternalUser();
      this.userName = this.userData.firstName+ ' ' + this.userData.lastName
    }
    this.cdr.detectChanges();
  }
  @HostListener('window:logoutUser', [ '$event' ])
  logoutUser(event:Event) {
    this.signOut(event);
  }
  
   signOut(event:any){
    sessionStorage.removeItem('userSignin');
    event.preventDefault();
    this.userData = '';
    this.userName = ''
    const payload = sessionStorage.getItem('refreshToken');
    if(this.isExternalUser){
      document.cookie = 'usasauthtoken' + "=;expires=Thu, 01 Jan 1900 00:00:00 GMT";
      this.headerLandingFlag = false;
      this.authService.logout();
      this.userDetailsService.ExternalLogout(payload).subscribe((data)=>{
      })
      window.location.href = '/signin';
    }else{
      this.userDetailsService.logOut(payload).subscribe((data)=>{
        document.cookie = 'usasauthtoken' + "=;expires=Thu, 01 Jan 1900 00:00:00 GMT";
        this.authService.logout();
        this.headerLandingFlag = false;
        window.location.href = atob(data);
      })
    }
    
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgot-password');
  }

  contactUs() {
    this.router.navigateByUrl('/contact-us');
  }
}


