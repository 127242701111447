<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">EXTERNAL USER/PASSWORD RESET</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">Navigate to the USAS sign in page ‘ <a
                        href="https://usas.faa.gov/"
                        target="_blank">https://usas.faa.gov/</a>’ and click on the ‘Forgot password?’ link to reset the password:
                    <img class="w-6 block" src="../../../assets/images/password1.png">
                </li>
                <li class="mb-3">Enter the email address associated with the account that you need to reset a password for on the ‘Request to Reset Password’ screen and click on ‘Next’.
                    <img class="w-6 block" src="../../../assets/images/password2.png">
                </li>
                <li class="mb-3">You will be
                    redirected to the screen where your will need to answer only ‘<strong>ONE</strong>’ of the security
                    questions
                    that were provided during account creation process. Enter the <strong>correct</strong> answer and
                    click on
                    ‘Submit’.
                    <img class="w-6 block" src="../../../assets/images/password3.png">
                </li>
                <li class="mb-3">You will see a
                    ‘Password Reset Confirmation’ message displayed.
                    <img class="w-6 block" src="../../../assets/images/image13.png">
                </li>
                <li class="mb-3">Log in to the email account and click on the ‘Reset Password’ link.
                    <img class="w-6 block" src="../../../assets/images/image14.png">
                </li>
                <li class="mb-3">Provide a new password according to the password requirements, confirm password and click on ‘Submit’.
                    <img class="w-6 block" src="../../../assets/images/password4.png">
                </li>
                <li class="mb-3">‘Password Changed Successfully!’ message will be displayed. Click on ‘Go to sign in’ button.
                    <img class="w-6 block" src="../../../assets/images/password5.png">
                </li>
                <li class="mb-3">You will be redirected to the disclaimer page. Click on ‘Accept’ button and you will be redirected to the ‘USAS sign in’ page.
                </li>
                <li class="mb-3">Use the email address and the updated password to sign into USAS.
                    <img class="w-6 block" src="../../../assets/images/password6.png">
                </li>
            </ol>
        </div>
    </div>
</div>