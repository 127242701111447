<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">MANAGE FAA USERS</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">‘Manage FAA Users’ link is available only to internal users with an admin role. 
                    <img class="w-6 block" src="../../../assets/images/manage-user-img1.png">
                </li>
                <li class="mb-3">Once logged in as an internal user with an admin role and on the ‘Manage FAA Users’ tab, default ‘Internal Users List’ will be displayed. The admin user will be able to use the filters for further refining the results. <b>Note:</b> The Internal users list is sorted by alphabetical order by default (First Name/ Last Name).</li>
                <li class="mb-3">Internal admins can search using any of the filters and click on ‘Search’ button to see the corresponding results. Click on ‘Clear’ button to clear the filters and to revert to default display.
                    <img class="w-6 block" src="../../../assets/images/search-image4.png">
                </li>
                <li>Internal admins also can add additional internal users by clicking on ‘Add New User’ button.</li>
                <li class="mb-3">You will be redirected to ‘Add New User’ screen, where you can enter the FAA email address and click on ‘Verify’.<b>Note:</b>  ‘Cancel’ button will clear the email entered in the ‘Email’ field.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img2.png">
                </li>
                <li class="mb-3">Once the email address is verified, the search results will be displayed. Click on ‘Select to assign role’ in the ‘Actions’ column.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img3.png">
                </li>
                <li class="mb-3">You can select the Role and the Status for the new user from the options in the drop-down list as in the screenshot below and click on ‘Save’.
                    <span style="display: flex;"><img class="w-4 block" src="../../../assets/images/manage-user-img4.png">
                    <img class="w-4 block" src="../../../assets/images/manage-user-img4.png"></span>
                </li>
                <li class="mb-3">A success message that the user has been added successfully will be displayed and the user will be redirected back to the ‘Manage FAA Users’ page.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img5.png">
                </li>
                <li class="mb-3">Once the user has been added to the ‘Internal Users List’, you can search for the new user by using the search filters and pagination. You can update the role or the status by clicking on ‘Edit’ link.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img6.png">
                </li>
                <li class="mb-3">The Internal users can also see the ‘Resource Links’ tab with the following sub menu items:
                    <ul>
                        <li>User Guide – (for Internal user functions)</li>
                        <li>FAQs</li>
                        <li>Contact US</li>
                    </ul>
                    <img class="w-6 block" src="../../../assets/images/manage-user-img7.png">
                </li>
            </ol>
        </div>
    </div>
</div>