import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomLoginService } from '../../shared/services/custom-login.service';

@Component({
  selector: 'app-confirm-verifcation',
  templateUrl: './confirm-verifcation.component.html',
  styleUrl: './confirm-verifcation.component.scss'
})
export class ConfirmVerifcationComponent {
  accountActivated:boolean ;
  isExpired:boolean;
  message:string;
  constructor(private route :ActivatedRoute, private customLoginService: CustomLoginService, public router: Router){
    
  }
  ngOnInit(): void {    
    this.ConfirmUserActivation(); 
  }

  ConfirmUserActivation(){
    const code = this.route.snapshot.queryParams['code'];
    const data = {
      "emailToId": "",
      "emailToName": "",
      "userUid": code
    }
    this.customLoginService.ConfirmUserActivation(data).subscribe(
      (data) => {
        if(data.status === true){
          this.accountActivated = true; 
        }else if(data.status === false && data.message === 'Your verification email link has expired. USAS System has sent a new link to your email.'){
          this.isExpired = true;
          this.message = 'Your verification email link has expired. USAS System has sent a new link to your email. Please check your inbox (and spam folder, just in case) to activate your account.<br/>If you need further assistance, please contact: <a href="mailto:APP-AIT-USAS@faa.gov">APP-AIT-USAS@faa.gov</a>';
        }else if(data.status === false && data.message !== 'Your verification email link has expired. USAS System has sent a new link to your email.'){
          this.isExpired = true;
          this.message = data.message;
        }
      },
      (error: any) => {
        this.accountActivated = false; 
      }
    );
  }
  signin(){
    this.router.navigateByUrl('/disclaimer');
  }
  

}
