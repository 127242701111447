<p-toast position="top-center"></p-toast>
<main id="main-content" class="grid nested-grid">
    <div class="col-8 mt-4 px-6">
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12">
                <h1>My Account</h1>
                <p class="text-sm">
                    Make any desired changes to your account and click the appropriate 'Submit' button.
                </p>
            </div>
        </div>
        <hr class="border-top-3 border-yellow-600">
        <!--Personal Details Section-->
        <div class="grid">
            <div class="lg:col-6 md:col-6 sm:col-12">
                <h2>Personal Information</h2>
            </div>
        </div>
        <div class="grid px-3 mt-2">
            <div class="form-grid grid">
                <form>
                    <div [formGroup]="userForm">
                        <div class="grid">
                            <div class="form-group field sm:col-12 md:col-4 lg:col-3">
                                <label for="firstName">First Name<span class="asterik">*</span><span class="fas fa-info-circle fa-sm ml-1" style="font-size: 1rem" pTooltip="Please enter NA if you do not have a First Name"></span></label>
                                <input type="text" id="firstName" formControlName="firstName" minlength="2" maxlength="30"
                                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
                                <div *ngIf="userForm.controls.firstName.invalid && userForm.controls.firstName.touched && userForm?.controls?.firstName?.errors?.required"
                                    class="alert danger-alert error-font">First Name is required.
                                </div>
                                <div *ngIf="userForm.controls.firstName.invalid && userForm.controls.firstName.touched && !userForm?.controls?.firstName?.errors?.required"
                                    class="alert danger-alert error-font">{{userForm.controls.firstName.value?.length < 2
                                        ? 'First name must contain between 2 and 30 characters.' :'Please enter a valid First
                                        Name.' }}
                                </div>
                            </div>
                            <div class="form-group field sm:col-12 md:col-4 lg:col-3">
                                <label for="middleName" style="margin-bottom: 10px;">Middle Name</label>
                                <input type="text" id="middletName" formControlName="middleName" maxlength="14"
                                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
                                <div *ngIf="userForm.controls.middleName.invalid && userForm.controls.middleName.touched"
                                    class="alert danger-alert error-font">Please enter a valid Middle Name.</div>
                            </div>
                            <div class="form-group field sm:col-12 md:col-4 lg:col-3">
                                <label for="lastName">Last Name<span class="asterik">*</span><span class="fas fa-info-circle fa-sm ml-1" style="font-size: 1rem" pTooltip="Please enter NA if you do not have a Last Name"></span></label>
                                <input type="text" id="lastName" formControlName="lastName" minlength="2" maxlength="30"
                                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
                                <div *ngIf="userForm.controls.lastName.invalid && userForm.controls.lastName.touched && userForm?.controls?.lastName?.errors?.required"
                                    class="alert danger-alert error-font">Last Name is required.</div>
                                <div *ngIf="userForm.controls.lastName.invalid && userForm.controls.lastName.touched && !userForm?.controls?.lastName?.errors?.required"
                                    class="alert danger-alert error-font">{{userForm.controls.lastName.value?.length < 2
                                        ? 'Last name must contain between 2 and 30 characters.' :'Please enter a valid Last
                                        Name.' }}
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="form-group field sm:col-12 md:col-6 lg:col-3">
                                <label for="email">Email<span class="asterik">*</span><span class="fas fa-info-circle fa-sm ml-1" style="font-size: 1rem" pTooltip="Please enter valid email (name@domain.com)"></span></label>
                                <input type="email" id="email" formControlName="email"
                                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
                                    <div *ngIf="userForm.controls.email.invalid && userForm.controls.email.touched && userForm?.controls?.email?.errors?.required"
                                    class="alert danger-alert error-font">Email is required.</div>
                                <div *ngIf="userForm.controls.email.invalid && userForm.controls.email.touched && !userForm?.controls?.email?.errors?.required && userForm?.controls?.email?.errors?.pattern"
                                    class="alert danger-alert error-font">Please enter a valid Email Format.</div>
                                <div *ngIf="userForm.controls.email.invalid && userForm.controls.email.touched && !userForm?.controls?.email?.errors?.required && !userForm?.controls?.email?.errors?.forbiddenDomainValidator && !userForm?.controls?.email?.errors?.pattern"
                                    class="alert danger-alert error-font">Email field
                                    doesn’t accept “&#64;faa.gov” email address.</div>
                            </div>
                            <div class="form-group field sm:col-12 md:col-6 lg:col-3">
                                <label for="confirmemail">Confirm Email<span class="asterik">*</span><span class="fas fa-info-circle fa-sm ml-1" style="font-size: 1rem" pTooltip="Please enter valid email (name@domain.com)" ></span></label>
                                <input type="email" id="confirmemail" formControlName="confirmEmail"
                                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
                                    <div *ngIf="userForm.controls.confirmEmail.invalid && userForm.controls.confirmEmail.touched && userForm?.controls?.confirmEmail?.errors?.required"
                                    class="alert danger-alert error-font">Confirm Email is required.</div>
                                    <div *ngIf="userForm.controls.email.invalid && userForm.controls.email.touched && !userForm?.controls?.email?.errors?.required && userForm?.controls?.email?.errors?.pattern"
                                    class="alert danger-alert error-font">Please enter a valid Email Format.</div>
                                <div *ngIf="userForm.controls.confirmEmail.invalid && userForm.controls.confirmEmail.touched && !userForm?.controls?.confirmEmail?.errors?.required && !userForm?.controls?.confirmEmail?.errors?.forbiddenDomainValidator && !userForm?.controls?.confirmEmail?.errors?.pattern"
                                    class="alert danger-alert error-font">Email field
                                    doesn’t accept “&#64;faa.gov” email address.</div>
                                <div *ngIf="userForm.controls.confirmEmail.touched && emailMismatch">
                                    <small class="alert danger-alert error-font">Email and Confirm Email should be same.</small>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col">
                                <button type="submit" [disabled]="( userForm.controls.confirmEmail.value !== userForm.controls.email.value || userForm.invalid )" [ngClass]="( userForm.controls.confirmEmail.value !== userForm.controls.email.value || userForm.invalid) ? 'disabled default': 'primary default'" (click)="UpdateExternalUserDetails()">Submit</button>
                            </div>
                        </div>
                    </div>
                    <hr class="border-top-1 border-gray-300">
                    <!--Security Questions-->
                    <div [formGroup]="questionsForm">
                        <div>
                            <div class="grid">
                                <div class="lg:col-12 md:col-12 sm:col-12">
                                    <h2>Security Questions and Answers</h2>
                                    <div *ngIf="!uniqueQuestion" class="alert danger-alert error-font">
                                        All three security questions must be unique.
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="lg:col-4 md:col-4 sm:col-6">
                                    <div class="form-grid grid">
                                        <div class="form-group field sm:col-11 md:col-12 lg:col-12">
                                            <label for="question1" class="block">Question 1<span
                                                    class="asterik">*</span></label>
                                            <p-dropdown formControlName="question1" [options]="questions"
                                                optionLabel="securityQuestionDesc"
                                                (onChange)="simpleValidateSecurityQuestion('question1', 'question1' , 'answer1')"
                                                [style]="{'border': '1px solid slategray', 'width': '100%'}"
                                                placeholder="Select a Question" />
                                            <div *ngIf="questionsForm.controls.question1.invalid && questionsForm.controls.question1.touched && questionsForm?.controls?.question1?.errors?.required"
                                                class="alert danger-alert error-font">Question 1 is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lg:col-4 md:col-4 sm:col-6">
                                    <div class="form-grid grid">
                                        <div class="form-group field sm:col-11 md:col-12 lg:col-12">
                                            <label for="answer1">Answer 1<span class="asterik">*</span></label>
                                            <input type="text" id="answer1" formControlName="answer1"
                                                (keyup)="simpleValidateSecurityQuestion('question1', 'question1' , 'answer1')"
                                                class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" [maxlength]="questionsForm.controls.question1.value?.securityQuestionDesc=='Four digit PIN' ? 4 : 120"/>
                                            <div *ngIf="questionsForm.controls.answer1.invalid && questionsForm.controls.answer1.touched && questionsForm?.controls?.answer1?.errors?.required"
                                                class="alert danger-alert error-font">Answer 1 is required.
                                            </div>
                                            <div *ngIf="questionsForm.controls.answer1.invalid && !questionsForm?.controls?.answer1?.errors?.required"
                                                class="alert danger-alert error-font">{{questionsForm.controls.answer1.value === "" ?
                                                'Answer 1 is required.' : answer1Error}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="lg:col-4 md:col-4 sm:col-6">
                                    <div class="form-grid grid">
                                        <div class="form-group field sm:col-11 md:col-12 lg:col-12">
                                            <label for="question2" class="block">Question 2<span
                                                    class="asterik">*</span></label>
                                            <p-dropdown formControlName="question2" [options]="questions"
                                                optionLabel="securityQuestionDesc" 
                                                (onChange)="simpleValidateSecurityQuestion('question2', 'question2' , 'answer2')"
                                                [style]="{'border': '1px solid slategray', 'width': '100%'}"
                                                placeholder="Select a Question" ></p-dropdown>
                                            <div *ngIf="questionsForm.controls.question2.invalid && questionsForm.controls.question2.touched && questionsForm?.controls?.question2?.errors?.required"
                                                class="alert danger-alert error-font">Question 2 is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lg:col-4 md:col-4 sm:col-6">
                                    <div class="form-grid grid">
                                        <div class="form-group field sm:col-11 md:col-12 lg:col-12">
                                            <label for="answer2">Answer 2<span class="asterik">*</span></label>
                                            <input type="text" id="answer2" formControlName="answer2"
                                                (keyup)="simpleValidateSecurityQuestion('question2', 'question2' , 'answer2')"
                                                class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" [maxlength]="questionsForm.controls.question2.value?.securityQuestionDesc=='Four digit PIN' ? 4 : 120"/>
                                            <div *ngIf="questionsForm.controls.answer2.invalid && questionsForm.controls.answer2.touched && questionsForm?.controls?.answer2?.errors?.required"
                                                class="alert danger-alert error-font">Answer 2 is required.</div>
                                            <div *ngIf="questionsForm.controls.answer2.invalid && !questionsForm?.controls?.answer2?.errors?.required"
                                                class="alert danger-alert error-font">{{questionsForm.controls.answer2.value === "" ?
                                                'Answer 2 is required.' : answer2Error}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="lg:col-4 md:col-4 sm:col-6">
                                    <div class="form-grid grid">
                                        <div class="form-group field sm:col-11 md:col-12 lg:col-12">
                                            <label for="question3" class="block">Question 3<span
                                                    class="asterik">*</span></label>
                                            <p-dropdown formControlName="question3" [options]="questions"
                                                optionLabel="securityQuestionDesc"
                                                (onChange)="simpleValidateSecurityQuestion('question3', 'question3' , 'answer3')"
                                                [style]="{'border': '1px solid slategray', 'width': '100%'}"
                                                placeholder="Select a Question" />
                                            <div *ngIf="questionsForm.controls.question3.invalid && questionsForm.controls.question3.touched && questionsForm?.controls?.question3?.errors?.required"
                                                class="alert danger-alert error-font">Question 3 is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lg:col-4 md:col-4 sm:col-6">
                                    <div class="form-grid grid">
                                        <div class="form-group field sm:col-11 md:col-12 lg:col-12">
                                            <label for="answer3">Answer 3<span class="asterik">*</span></label>
                                            <input type="text" id="answer3" formControlName="answer3"
                                                (keyup)="simpleValidateSecurityQuestion('question3', 'question3' , 'answer3')"
                                                class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" [maxlength]="questionsForm.controls.question3.value?.securityQuestionDesc=='Four digit PIN' ? 4 : 120"/>
                                            <div *ngIf="questionsForm.controls.answer3.invalid && questionsForm.controls.answer3.touched && questionsForm?.controls?.answer3?.errors?.required"
                                                class="alert danger-alert error-font">Answer 3 is required.</div>
                                            <div *ngIf="questionsForm.controls.answer3.invalid && !questionsForm?.controls?.answer3?.errors?.required"
                                                class="alert danger-alert error-font">{{questionsForm.controls.answer3.value === "" ?
                                                'Answer 3 is required.' : answer3Error}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col">
                                    <button type="submit" [disabled]="questionsForm.invalid" [ngClass]="questionsForm.invalid ? 'disabled default': 'primary default'" (click)="UpdateSecurityQuestions()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="border-top-1 border-gray-300">
                    <!--Setup Password Section-->
                    <div class="grid">
                        <div class="lg:col-6 md:col-6 sm:col-12">
                            <h2>Create Password</h2>
                        </div>
                    </div>
                    <div [formGroup]="passwordForm">
                        <div>
                            <div class="lg:col-8 md:col-10 sm:col-12 bg-cyan-50 p-3 border-round-md border-300 border-1">
                                <h4>Password Requirements</h4>
                                <ul class="list-disc">
                                    <li>Password is case sensitive</li>
                                    <li>Password must contain between 12 to 24 characters</li>
                                    <li>Password must begin with a letter</li>
                                    <li>No character may be repeated in sequence</li>
                                    <li>Password must include four types of characters:
                                        <ul>
                                            <li>English upper case letter (A through Z)</li>
                                            <li>English lower case letter (a through z)</li>
                                            <li>Number (0 through 9)</li>
                                            <li>Only !, #, $, and % special characters are allowed</li>
                                            <li>Spaces before, after or in between passwords are not allowed</li>
                                        </ul>
                                    </li>
                                    <li>Password must not match previously used passwords</li>
                                </ul>
                            </div>

                            <div class="grid px-2 my-2">
                                <div class="form-grid grid">
                                    <div class="form-group field col-12">
                                        <label for="oldPassword">Old Password<span class="asterik">*</span></label>
                                        <p-password id="oldPassword" formControlName="oldPassword"
                                        [feedback]="false" [toggleMask]="true" class="block border-noround"/>
                                        <div *ngIf="passwordForm.controls.oldPassword.invalid && passwordForm.controls.oldPassword.touched && passwordForm?.controls?.oldPassword?.errors?.required"
                                            class="alert danger-alert error-font">Password is required.</div>
                                        <div *ngIf="passwordForm.controls.oldPassword.invalid && passwordForm.controls.oldPassword.touched && !passwordForm?.controls?.oldPassword?.errors?.required"
                                            class="alert danger-alert error-font">{{newPasswordError}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="grid px-2 my-2">
                                <div class="form-grid grid">
                                    <div class="form-group field col-12">
                                        <label for="newPassword">New Password<span class="asterik">*</span></label>
                                        <p-password id="newPassword" formControlName="newPassword"
                                        (keyup)="validatePassword('newPassword', 'newPassword')" [feedback]="false" [toggleMask]="true" class="block border-noround"/>
                                        <div *ngIf="passwordForm.controls.newPassword.invalid && passwordForm.controls.newPassword.touched && passwordForm?.controls?.newPassword?.errors?.required"
                                            class="alert danger-alert error-font">Password is required.</div>
                                        <div *ngIf="passwordForm.controls.newPassword.invalid && passwordForm.controls.newPassword.touched && !passwordForm?.controls?.newPassword?.errors?.required"
                                            class="alert danger-alert error-font">{{newPasswordError}}</div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="grid px-2">
                                <div class="form-grid grid">
                                    <div class="form-group field col-12">
                                        <label for="confirmPassword">Confirm Password<span class="asterik">*</span></label>
                                        <p-password id="confirmPassword" formControlName="confirmPassword"
                                        (keyup)="validatePassword('confirmPassword', 'confirmPassword')" [feedback]="false" [toggleMask]="true" class="block border-noround"/>
                                        <div *ngIf="passwordForm.controls.confirmPassword.invalid && passwordForm.controls.confirmPassword.touched && passwordForm?.controls?.confirmPassword?.errors?.required"
                                            class="alert danger-alert error-font">Confirm Password is required.</div>
                                        <div *ngIf="passwordForm.controls.confirmPassword.invalid && passwordForm.controls.confirmPassword.touched && !passwordForm?.controls?.confirmPassword?.errors?.required"
                                            class="alert danger-alert error-font">{{confirmPasswordError}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col">
                                    <button type="submit" [disabled]="passwordForm.invalid" [ngClass]="passwordForm.invalid ? 'disabled default': 'primary default'" (click)="UpdateUserPassword()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>