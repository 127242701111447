import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../../_auth/auth.service';
import { UserDataService } from '../../shared/services/user-data.service';
import { TitleBlinker } from '../../shared/services/tab-blink.service'
import { UserIdleService } from 'angular-user-idle';
import { UserDetailsService } from '../../shared/services/user-details.service';

@Component({
  selector: 'app-user-idle-inactive-timeout',
  templateUrl: './user-idle-inactive-timeout.component.html',
  styleUrls: ['./user-idle-inactive-timeout.component.scss']
})
export class UserIdleInactiveTimeoutComponent implements OnInit {
  public openModal: boolean = false;
  display: boolean = true;
  tokenAvailabilitySubscribe: any;
  endTime: number;
  timeoutWarning: number;
  message:string='';
  constructor(private userDataService: UserDataService, private titleBlinker: TitleBlinker, private authService: AuthService,
    private userDetailsService: UserDetailsService, private userIdle: UserIdleService
  ) {
  }

  ngOnInit() {
    this.tokenAvailabilitySubscribe = this.userDataService.getTokenAvailability().pipe().subscribe(tokenAvailable => {
      if (tokenAvailable) {
        this.timeoutWarning = this.authService.getUserData().warningTime * 60;
    	  this.endTime = this.authService.getUserData().idleTimeout * 60 - this.timeoutWarning;
        this.userIdle.setConfigValues({idle: this.endTime, timeout: this.timeoutWarning, ping: 120 })
        this.startSessionTimeout();
        this.stopSessionTimeout();
      }
    });
  }

  ngOnDestroy() {
    this.stopSessionTimeout();
  }

  private startSessionTimeout() {
    this.openModal = false;
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => {
        this.openModal = true;
        let remainingTime = this.timeoutWarning - count;
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        this.message = `Your session will expire in ${minutes}:${seconds.toString().padStart(2, '0')} seconds. Any unsaved data will be lost.`;
    });
  }

  private stopSessionTimeout() {
    this.userIdle.onTimeout().subscribe(() => {
      this.openModal = false;
      this.logoutUser();
      this.userIdle.stopWatching();
    });
  }

  public resetSessionTimeout() {
    const isExternalUser = this.authService.isExternalUser() ? 'Y': 'N'
    const payload = {
      isExternalUser: isExternalUser,
      refreshToken: sessionStorage.getItem('refreshToken')
    }
    this.userDetailsService.RefreshAccessToken(payload).subscribe((data)=> {
      sessionStorage.setItem('refreshToken', data.refreshToken);
      sessionStorage.setItem('usasToken', data.accessToken);
    })
    this.userIdle.resetTimer();
    this.openModal = false;
  }

  logoutUser() {
    window.dispatchEvent(new CustomEvent("logoutUser"));
  }

}

