import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailMatchValidator(email: string, confirmEmail: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailControl = control.get(email);
    const confirmEmailControl = control.get(confirmEmail);

    if (emailControl && confirmEmailControl && emailControl.value !== confirmEmailControl.value) {
      return { 'emailMismatch': true };
    }
    return null;
  };
}